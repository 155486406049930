import type { ReactElement, SyntheticEvent } from "react";
import React, { useEffect, useState } from "react";

import type { SelectChangeEvent } from "@mui/material";
import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import postSelectorFormData from "../api/Selector/postSelectorFormData";
import Button from "../components/Button";
import FixturesTable from "../components/FixturesTable/FixturesTable";
import useFixturesTable from "../components/FixturesTable/useFixturesTable";
import NewDropDownField from "../components/NewDropDownField/NewDropDownField";
import useNewDropDownField from "../components/NewDropDownField/useNewDropDownField";
import NewTextField from "../components/NewTextField/NewTextField";
import useNewTextField from "../components/NewTextField/useNewTextField";
import RadioField from "../components/Radio/Radio";
import useRadio from "../components/Radio/useRadio";
import TimeField from "../components/TimeField/TimeField";
import useTimeField from "../components/TimeField/useTimeField";
import Title from "../components/Title";
import {
  AlertTextPot,
  PotBuilderFormHeaders,
  PotBuilderFormOptions,
  PotCreatorForm,
} from "../config/Text";
import { SelectorBetmojis } from "../data/Betmojis";
import type { Selector } from "../interfaces/Selector";
import { alertBanner } from "../screens/CreateFantasyPot";

import { PayoutStructureSuggestions } from "./DataForSelectorInputs";
import type { BetBuilderQuestion } from "./SelectorOptions";
import {
  BetBuilderQuestions,
  PayoutTypeOptions,
  SelectorPotTypes,
  fourFixtureSuggestedQuestions,
  oneFixtureSuggestedQuestions,
  threeFixtureSuggestedQuestions,
  twoFixtureSuggestedQuestions,
} from "./SelectorOptions";
import useBetBuilderQuestions from "./useBetBuilderQuestions";
import {
  validateDisplayTime,
  validateDisplayTimeAfterPotStartTime,
  validateEntryFee,
  validateJackpotPayoutAmount,
  validateJackpotPointsThreshold,
  validateMaxEntrants,
  validateMaxEntrantsPerUser,
  validatePayout,
  validatePotName,
  validatePotStartTime,
} from "./ValidationFunctions";

dayjs.extend(utc);

const CreateBuilderPot = (): ReactElement => {
  const [selectorPotType, handleSelectorPotTypeChange, resetSelectorPotType] = useRadio(
    SelectorPotTypes[0]
  );

  const {
    fixtureData,
    checkedTeamId,
    fixtureIds,
    fixtureError,
    handleFixtureClick,
    handleFixtureClickAway,
    handleFixtureChange,
    loadFixtures,
  } = useFixturesTable();

  // Load fixtures when component mounts
  useEffect(() => {
    loadFixtures();
  }, []);

  const [potName, potNameError, handlePotNameChange, resetPotName] =
    useNewTextField(validatePotName);
  const handleAndCapitalisePotNameChange = (newValue: string) => {
    newValue = newValue.toUpperCase();
    handlePotNameChange(newValue);
  };

  const [leftIcon, handleLeftIconChange, resetLeftIcon] = useNewDropDownField();
  const [rightIcon, handleRightIconChange, resetRightIcon] = useNewDropDownField();
  const [payout, payoutError, handlePayoutChange, resetPayout] = useNewTextField(validatePayout);
  const [entryFee, entryFeeError, handleEntryFeeChange, resetEntryFee] =
    useNewTextField(validateEntryFee);
  const [
    jackpotPayoutAmount,
    jackpotPayoutAmountError,
    handleJackpotPayoutAmountChange,
    resetJackpotPayoutAmount,
  ] = useNewTextField(validateJackpotPayoutAmount);
  const [
    jackpotPointsThreshold,
    jackpotPointsThresholdError,
    handleJackpotPointsThresholdChange,
    resetJackpotPointsThreshold,
  ] = useNewTextField((value) => validateJackpotPointsThreshold(value, jackpotPayoutAmount));
  const [maxEntrants, maxEntrantsError, handleMaxEntrantsChange, resetMaxEntrants] =
    useNewTextField(validateMaxEntrants);
  const [
    maxEntrantsPerUser,
    maxEntrantsPerUserError,
    handleMaxEntrantsPerUserChange,
    resetMaxEntrantsPerUser,
  ] = useNewTextField(validateMaxEntrantsPerUser);
  const [potCode, potCodeError, handlePotCodeChange, resetPotCode] = useNewTextField();
  const handleAndCapitalisePotCodeChange = (newValue: string) => {
    newValue = newValue.toUpperCase();
    handlePotCodeChange(newValue);
  };
  const [numberOfWinners, handleNumberOfWinnersChange, resetNumberOfWinners] =
    useNewDropDownField();
  const [payoutStructure, payoutStructureError, handlePayoutStructureChange, resetPayoutStructure] =
    useNewTextField();
  const handleNumberOfWinnersandPayoutStructureChange = (event: SelectChangeEvent<string>) => {
    handleNumberOfWinnersChange(event.target.value);
    handlePayoutStructureChange(event.target.value as string);
  };
  const [payoutType, handlePayoutTypeChange, resetPayoutType] = useRadio(
    PotBuilderFormOptions.money
  );
  const [potStartTime, potStartTimeError, handlePotStartTimeChange, resetPotStartTime] =
    useTimeField(validatePotStartTime);
  const [displayTime, displayTimeError, handleDisplayTimeChange, resetDisplayTime] = useTimeField(
    (value) => {
      const basicValidation = validateDisplayTime(value);
      if (basicValidation) {
        return basicValidation;
      }

      return validateDisplayTimeAfterPotStartTime(value, potStartTime);
    }
  );

  const [selectedQuestions, handleQuestionChange, resetQuestions] = useBetBuilderQuestions();

  const [alert, setAlert] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertErrorMessage, setAlertErrorMessage] = useState<string>("");
  const [progress, setProgress] = useState<number>();

  const handleClearButtonPressed = () => {
    resetSelectorPotType();
    resetPotName();
    resetLeftIcon();
    resetRightIcon();
    resetPayout();
    resetEntryFee();
    resetJackpotPayoutAmount();
    resetJackpotPointsThreshold();
    resetMaxEntrants();
    resetMaxEntrantsPerUser();
    resetPotCode();
    resetPayoutType();
    resetNumberOfWinners();
    resetPayoutStructure();
    resetPotStartTime();
    resetDisplayTime();
    resetQuestions();
  };

  useEffect(() => {
    //runs when the selector pot type changes
    //designed to run before any fixtures are selected
    if (selectorPotType === PotBuilderFormOptions.fullTimeResult) {
      handlePotCodeChange("FT-");
      handleMaxEntrantsPerUserChange("5");
      handleNumberOfWinnersChange("11.5-9-8.5-8-7.5-7-6.5-6-5.5-5-4.5-4-3.5-3-2.5-2-2-1.5-1.5-1");
      handlePayoutStructureChange("11.5-9-8.5-8-7.5-7-6.5-6-5.5-5-4.5-4-3.5-3-2.5-2-2-1.5-1.5-1");
    } else if (selectorPotType === PotBuilderFormOptions.bothTeamsToScore) {
      handlePotCodeChange("BTTS-");
      handleMaxEntrantsPerUserChange("5");
      handleNumberOfWinnersChange("40-22-18-12-8");
      handlePayoutStructureChange("40-22-18-12-8");
    } else if (selectorPotType === PotBuilderFormOptions.goalScorer) {
      handlePotCodeChange("GS-");
      handleMaxEntrantsPerUserChange("5");
      handleNumberOfWinnersChange("40-22-18-12-8");
      handlePayoutStructureChange("40-22-18-12-8");
    } else if (selectorPotType === "Bet Builder") {
      handlePotCodeChange("BB-");
      handleMaxEntrantsPerUserChange("5");
      handleNumberOfWinnersChange("60-25-15");
      handlePayoutStructureChange("60-25-15");
    }
  }, [selectorPotType]);

  // Add effect to update form fields when a fixture is selected
  useEffect(() => {
    if (fixtureIds.length >= 1) {
      // Find all selected fixtures
      const selectedFixtures = fixtureData
        .flatMap((comp) => comp.fixtures)
        .filter((fixture) => fixtureIds.includes(fixture.fixtureId));

      if (selectedFixtures.length > 0) {
        // If exactly one fixture is selected, update icons and pot name
        if (fixtureIds.length === 1) {
          const selectedFixture = selectedFixtures[0];
          handleLeftIconChange(`:crest-${selectedFixture.homeClubId}:`);
          handleRightIconChange(`:crest-${selectedFixture.awayClubId}:`);
          handlePotNameChange(`${selectedFixture.homeClubName} vs ${selectedFixture.awayClubName}`);

          const existingPrefix = potCode.split("-")[0] + "-";
          handlePotCodeChange(
            `${existingPrefix}${selectedFixture.homeClubShortName}-${selectedFixture.awayClubShortName}`
          );
        }

        if (fixtureIds.length >= 2) {
          handlePotNameChange("");
          if (selectorPotType === "Full Time Result") {
            handleLeftIconChange(`:ftresult:`);
            handleRightIconChange(`:ftresult:`);
          } else if (selectorPotType === "Goal Scorer") {
            handleLeftIconChange(`:goalscorer:`);
            handleRightIconChange(`:goalscorer:`);
          } else if (selectorPotType === "Both Teams To Score") {
            handleLeftIconChange(`:btts:`);
            handleRightIconChange(`:btts:`);
          } else if (selectorPotType === "Bet Builder") {
            handleLeftIconChange(`:builder:`);
            handleRightIconChange(`:builder:`);
          }
        }

        // If Bet Builder is selected, handle suggested questions based on number of fixtures
        if (selectorPotType === "Bet Builder") {
          // Reset any previously selected questions
          resetQuestions();

          // Determine which set of suggested questions to use based on fixture count
          let suggestedQuestions;
          switch (fixtureIds.length) {
            case 1:
              suggestedQuestions = oneFixtureSuggestedQuestions;
              break;
            case 2:
              suggestedQuestions = twoFixtureSuggestedQuestions;
              break;
            case 3:
              suggestedQuestions = threeFixtureSuggestedQuestions;
              break;
            case 4:
              suggestedQuestions = fourFixtureSuggestedQuestions;
              break;
            default:
              // More than 4 fixtures or 0 fixtures - don't preselect any questions
              suggestedQuestions = [];
          }

          // Find and select the suggested questions in the correct order
          suggestedQuestions.forEach((questionType, index) => {
            // Find the question with matching type
            const question = BetBuilderQuestions.find((q) => q.type === questionType);
            if (question) {
              // Add the question with the correct order (index + 1)
              handleQuestionChange(question, true, index + 1);
            }
          });
        }

        // Find the earliest fixture start time
        const earliestFixture = selectedFixtures.reduce((earliest, current) => {
          const earliestTime = dayjs(earliest.kickOffDateTimeUtc);
          const currentTime = dayjs(current.kickOffDateTimeUtc);
          return currentTime.isBefore(earliestTime) ? current : earliest;
        }, selectedFixtures[0]);

        // Set pot start time to the earliest fixture start time
        handlePotStartTimeChange(dayjs(earliestFixture.kickOffDateTimeUtc));
      }
    }
  }, [checkedTeamId, selectorPotType]);

  const isCreatePotButtonEnabled = (): boolean => {
    // Check for any validation errors
    const hasNoErrors =
      !fixtureError &&
      !potNameError &&
      !entryFeeError &&
      !maxEntrantsError &&
      !payoutStructureError &&
      !payoutError &&
      !potStartTimeError &&
      !displayTimeError &&
      !potCodeError &&
      !maxEntrantsPerUserError &&
      !jackpotPayoutAmountError &&
      !jackpotPointsThresholdError;

    // Check that all required fields have values
    const requiredFieldsFilled =
      fixtureIds.length > 0 &&
      potName.length > 0 &&
      entryFee.length > 0 &&
      payout.length > 0 &&
      potCode.length > 0 &&
      potStartTime !== null &&
      maxEntrantsPerUser.length > 0 &&
      payoutStructure.length > 0;

    // Special case for jackpot fields - either both must be filled or both must be empty
    const jackpotFieldsValid =
      (jackpotPayoutAmount.length === 0 && jackpotPointsThreshold.length === 0) ||
      (jackpotPayoutAmount.length > 0 && jackpotPointsThreshold.length > 0);

    return hasNoErrors && requiredFieldsFilled && jackpotFieldsValid;
  };

  const createPot = async () => {
    // Find all selected fixtures
    const selectedFixtures = fixtureData
      .flatMap((comp) => comp.fixtures)
      .filter((fixture) => fixtureIds.includes(fixture.fixtureId));

    // Find the latest fixture start time
    const latestFixture = selectedFixtures.reduce((latest, current) => {
      const latestTime = dayjs(latest.kickOffDateTimeUtc);
      const currentTime = dayjs(current.kickOffDateTimeUtc);
      return currentTime.isAfter(latestTime) ? current : latest;
    }, selectedFixtures[0]);

    const lastFixtureStartTime = latestFixture.kickOffDateTimeUtc;

    const builderQuestions =
      selectorPotType === "Bet Builder"
        ? selectedQuestions.map((question) => ({
            questionId: question.questionId,
            order: question.order,
          }))
        : null;

    const tempData: Selector = {
      leftIcon,
      rightIcon,
      potName: potName,
      potType: selectorPotType,
      potCode: potCode,
      entryFee,
      payoutStructure: payoutStructure,
      payout,
      potStartTime,
      lastFixtureStartTime: dayjs(lastFixtureStartTime),
      displayTime,
      fixtureIds,
      maxEntrants,
      maxEntrantsPerUser,
      winningsMoney: payoutType === "Money",
      jackpotPayoutAmount,
      jackpotPointsThreshold,
      builderQuestions: builderQuestions,
    };

    try {
      await postSelectorFormData(tempData);
      setAlert("progress");
      setProgress(0);
      setAlertOpen(true);
      setProgress(50);
      setProgress(100);
      setAlert("success");
      setAlertOpen(true);
    } catch (err) {
      setAlertErrorMessage(AlertTextPot.error);
      setAlert("error");
      setAlertOpen(true);
      console.log(err);
    }
  };

  const handleAlertClose = (_event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };
  return (
    <Grid
      container
      spacing={8}
      sx={{
        padding: "32px",
      }}
    >
      {alertBanner(alert, alertOpen, handleAlertClose, progress, alertErrorMessage)}
      <Grid item xs={12} lg={6}>
        <RadioField
          value={selectorPotType}
          label={PotCreatorForm.potType}
          onChange={handleSelectorPotTypeChange}
          options={SelectorPotTypes}
        />
        <FixturesTable
          fixtureData={fixtureData}
          checkedTeamId={checkedTeamId}
          fixtureIds={fixtureIds}
          fixtureError={fixtureError}
          handleClick={handleFixtureClick}
          handleClickAway={handleFixtureClickAway}
          handleChange={handleFixtureChange}
        />

        <Title title={PotBuilderFormHeaders.money} />
        <NewTextField
          value={payout}
          label={PotCreatorForm.payout}
          onChange={(event) => handlePayoutChange(event.target.value)}
          error={!!payoutError}
          helperText={payoutError}
          placeholder={PotCreatorForm.payout}
          required
          startAdornment={"£"}
        />
        <NewTextField
          value={entryFee}
          label={PotCreatorForm.entryFee}
          onChange={(event) => handleEntryFeeChange(event.target.value)}
          error={!!entryFeeError}
          helperText={entryFeeError}
          placeholder={PotCreatorForm.entryFee}
          required
          startAdornment={"£"}
        />
        <NewTextField
          value={jackpotPayoutAmount}
          label={PotCreatorForm.jackpotPayoutAmount}
          onChange={(event) => handleJackpotPayoutAmountChange(event.target.value)}
          error={!!jackpotPayoutAmountError}
          helperText={jackpotPayoutAmountError}
          placeholder={PotCreatorForm.jackpotPayoutAmount}
          startAdornment={"£"}
        />
        <NewTextField
          value={jackpotPointsThreshold}
          label={PotCreatorForm.jackpotPointsThreshold}
          onChange={(event) => handleJackpotPointsThresholdChange(event.target.value)}
          error={!!jackpotPointsThresholdError}
          helperText={jackpotPointsThresholdError}
          placeholder={PotCreatorForm.jackpotPointsThreshold}
        />
        <NewDropDownField
          label={PotCreatorForm.numberOfWinners}
          value={numberOfWinners}
          onChange={handleNumberOfWinnersandPayoutStructureChange}
          options={PayoutStructureSuggestions}
        />
        <NewTextField
          value={payoutStructure}
          label={PotCreatorForm.payoutStructure}
          onChange={(event) => handlePayoutStructureChange(event.target.value)}
          error={!!payoutStructureError}
          helperText={payoutStructureError}
          placeholder={PotCreatorForm.payoutStructure}
        />
        <RadioField
          value={payoutType}
          label={PotCreatorForm.winningsPaid}
          onChange={handlePayoutTypeChange}
          options={PayoutTypeOptions}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Title title={PotBuilderFormHeaders.naming} />
        <NewDropDownField
          label={PotCreatorForm.leftIcon}
          value={leftIcon}
          onChange={(event) => handleLeftIconChange(event.target.value)}
          options={SelectorBetmojis}
        />
        <NewDropDownField
          label={PotCreatorForm.rightIcon}
          value={rightIcon}
          onChange={(event) => handleRightIconChange(event.target.value)}
          options={SelectorBetmojis}
        />
        <NewTextField
          value={potName}
          label={PotCreatorForm.potName}
          onChange={(event) => handleAndCapitalisePotNameChange(event.target.value)}
          error={!!potNameError}
          helperText={potNameError}
          placeholder={PotCreatorForm.potName}
          required
        />
        <Title title={PotBuilderFormHeaders.potInformation} />
        <NewTextField
          value={maxEntrants}
          label={PotCreatorForm.maxEntrants}
          onChange={(event) => handleMaxEntrantsChange(event.target.value)}
          error={!!maxEntrantsError}
          helperText={maxEntrantsError}
          placeholder={PotCreatorForm.maxEntrants}
        />
        <NewTextField
          value={maxEntrantsPerUser}
          label={PotCreatorForm.maxEntrantsPerUser}
          onChange={(event) => handleMaxEntrantsPerUserChange(event.target.value)}
          error={!!maxEntrantsPerUserError}
          helperText={maxEntrantsPerUserError}
          placeholder={PotCreatorForm.maxEntrantsPerUser}
        />
        <Title title={PotBuilderFormHeaders.gameInformation} />
        <NewTextField
          value={potCode}
          label={PotCreatorForm.potCode}
          onChange={(event) => handleAndCapitalisePotCodeChange(event.target.value)}
          error={!!potCodeError}
          helperText={potCodeError}
          placeholder={PotCreatorForm.potCode}
          required
        />
        <TimeField
          value={potStartTime}
          label={PotCreatorForm.potStartTime}
          onChange={handlePotStartTimeChange}
          dateOnly={false}
          error={potStartTimeError}
        />
        <TimeField
          value={displayTime}
          label={PotCreatorForm.displayTime}
          onChange={handleDisplayTimeChange}
          dateOnly={false}
          error={displayTimeError}
        />
        {selectorPotType === "Bet Builder" && (
          <>
            <Title title={"Bet Builder Questions"} />
            <Box sx={{ maxHeight: "500px", overflow: "scroll" }}>
              {BetBuilderQuestions.map((question: BetBuilderQuestion): ReactElement => {
                // Check if this question is selected
                const isSelected = selectedQuestions.some(
                  (q) => q.questionId === question.questionId
                );

                return (
                  <Box key={question.questionId}>
                    <FormControlLabel
                      sx={{ padding: "0px 32px" }}
                      value={question.questionId}
                      control={
                        <Checkbox
                          checked={isSelected}
                          onChange={(e) => handleQuestionChange(question, e.target.checked)}
                        />
                      }
                      label={`${question.title} (${question.points} points)${
                        isSelected
                          ? ` - Order: ${
                              selectedQuestions.find((q) => q.questionId === question.questionId)
                                ?.order
                            }`
                          : ""
                      }`}
                    />
                  </Box>
                );
              })}
            </Box>

            {selectedQuestions.length > 0 && (
              <Box sx={{ mt: 2, p: 2, border: "1px solid #eee", borderRadius: 1 }}>
                <Typography variant="subtitle1">
                  Selected Questions ({selectedQuestions.length})
                </Typography>
                {selectedQuestions
                  .sort((a, b) => a.order - b.order)
                  .map((q) => (
                    <Typography key={q.questionId} variant="body2">
                      {q.order}. {q.title} ({q.points} points)
                    </Typography>
                  ))}
              </Box>
            )}
          </>
        )}

        <Button
          enabled={isCreatePotButtonEnabled()}
          onClick={() => {
            createPot();
          }}
        >
          Create Pot
        </Button>

        <Button onClick={handleClearButtonPressed} variant="outlined">
          Clear
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateBuilderPot;
