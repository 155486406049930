import type { ReactElement } from "react";
import React from "react";

import Box from "@mui/material/Box";

import "../config/Styling.css";

import BetmateLogo from "../assets/BetmateLogo";
import { bannersText } from "../config/Text";

const Banner = (): ReactElement => {
  let statusColor = "#fc9300";
  let statusText = bannersText.dev;

  const siteStatus = (color: string, text: string) => {
    return (
      <Box
        sx={{
          backgroundColor: `${color}`,
          color: "#ffffff",
          pd: "4",
          width: 55,
          height: 25,
          borderRadius: "16px",
          textAlign: "center",
        }}
      >
        {text}
      </Box>
    );
  };

  if (window.location.origin === "https://nimda.betmate.app") {
    statusColor = "#06a600";
    statusText = bannersText.prod;
  }

  return (
    <>
      <Box className="Banner">
        <BetmateLogo />
      </Box>
      {siteStatus(statusColor, statusText)}
    </>
  );
};

export default Banner;
