import type { ReactElement } from "react";
import React from "react";

interface TitleProps {
  title: string;
}

const Title = ({ title }: TitleProps): ReactElement => {
  return <div className={"H5BoldLeftSecondaryPressed"}>{title}</div>;
};

export default Title;
