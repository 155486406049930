import type { ReactElement } from "react";
import React, { useContext, useEffect } from "react";

import { Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "@fontsource/kanit";
import PrivateRoute from "./components/PrivateRoute";
import Colors from "./config/Colors";
import { LoginContext, LoginProvider } from "./context/LoginContext";
import { UserContext, UserProvider } from "./context/UserContext";
import CreateBuilderPot from "./CreateBuilderPot/CreateBuilderPot";
import type { LoginContextInterface, LoginInfoInterface } from "./interfaces/Login";
import type { UserContextInterface, UserInfoInterface } from "./interfaces/User";
import CaptureScreen from "./screens/CaptureScreen";
import CarouselScreen from "./screens/CarouselScreen";
import CreateFantasyPot from "./screens/CreateFantasyPot";
import FreeBetScreen from "./screens/FreeBetScreen";
import LoginScreen from "./screens/LoginScreen";
import MetricsScreen from "./screens/MetricsScreen";
import Layout from "./screens/navigation/Layout";
import SelectorScreen from "./screens/SelectorScreen";
import VerifyUserScreen from "./screens/VerifyUserScreen";
import UserInfoScreen from "./UserInfo/screens/UserInfoScreen";

const theme = createTheme({
  typography: {
    fontFamily: ["kanit"].join(","),
  },
  palette: {
    primary: {
      main: Colors.PrimaryVariants100,
      light: Colors.PrimaryVariants050,
      dark: Colors.PrimaryVariants200,
      contrastText: Colors.Base050,
    },
    secondary: {
      main: Colors.SecondaryVariant100,
    },
    text: {
      primary: Colors.PrimaryVariants200,
      secondary: Colors.Primary900,
      disabled: Colors.Base500,
    },
    background: {
      default: Colors.Base050,
      paper: Colors.Base300,
    },
  },
  shape: {
    borderRadius: 28,
  },
});

const AppStackWithoutProvider = (): ReactElement => {
  const { setLoginInfo, loginInfo, isLoggedIn, setIsLoggedIn, sessionTime, setSessionTime } =
    useContext<LoginInfoInterface>(LoginContext);
  const { userInfo, setUserInfo } = useContext<UserInfoInterface>(UserContext);

  useEffect(() => {
    let localLoginInfo: LoginContextInterface;
    let localUserInfo: UserContextInterface;
    let localSessionTime: number;
    let localIsLoggedIn: boolean;

    const getLocalLoginInfo: string | null = localStorage.getItem("loginInfo");
    const getLocalUserInfo: string | null = localStorage.getItem("userInfo");
    const getLocalSessionTime: string | null = localStorage.getItem("sessionTime");
    const getLocalIsLoggedIn: string | null = localStorage.getItem("isLoggedIn");

    const localContextNotEmpty =
      getLocalLoginInfo != null &&
      getLocalUserInfo != null &&
      getLocalSessionTime != null &&
      getLocalIsLoggedIn != null;
    const contextEmpty = loginInfo?.accessToken === null || loginInfo?.accessToken === undefined;
    const contextNotEmpty = loginInfo?.accessToken !== null && loginInfo?.accessToken !== undefined;

    if (localContextNotEmpty && contextEmpty) {
      localLoginInfo = JSON.parse(getLocalLoginInfo);
      localUserInfo = JSON.parse(getLocalUserInfo);
      localSessionTime = JSON.parse(getLocalSessionTime);
      localIsLoggedIn = JSON.parse(getLocalIsLoggedIn);

      setLoginInfo(localLoginInfo);
      setUserInfo(localUserInfo);
      setSessionTime(localSessionTime);
      setIsLoggedIn(localIsLoggedIn);
    } else if (contextNotEmpty && localContextNotEmpty === false) {
      localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
      localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
      localStorage.setItem("sessionTime", JSON.stringify(sessionTime));
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
  });

  const routes = (
    <Routes>
      <Route path="/login" element={<LoginScreen />} id={"Login"} />
      <Route path="/Capture" element={<CaptureScreen />} id={"Capture"} />
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<PrivateRoute element={<CreateFantasyPot />} />} id={"Home"} />
        <Route
          path="/AddFreeBets"
          element={<PrivateRoute element={<FreeBetScreen />} />}
          id={"AddFreeBets"}
        />
        <Route
          path="/VerifyUser"
          element={<PrivateRoute element={<VerifyUserScreen />} />}
          id={"VerifyUser"}
        />
        <Route
          path="/Carousel"
          element={<PrivateRoute element={<CarouselScreen />} />}
          id={"Carousel"}
        />
        <Route
          path="/Metrics"
          element={<PrivateRoute element={<MetricsScreen />} />}
          id={"Metrics"}
        />
        <Route
          path="/Selector"
          element={<PrivateRoute element={<SelectorScreen />} />}
          id={"Selector"}
        />
        <Route
          path="/CreateBuilderPot"
          element={<PrivateRoute element={<CreateBuilderPot />} />}
          id={"CreateBuilderPot"}
        />
        <Route
          path="/UserInfo"
          element={<PrivateRoute element={<UserInfoScreen />} />}
          id={"UserInfo"}
        />
      </Route>
    </Routes>
  );

  return (
    <Container>
      <BrowserRouter>{routes}</BrowserRouter>
    </Container>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LoginProvider>
        <UserProvider>
          <AppStackWithoutProvider />
        </UserProvider>
      </LoginProvider>
    </ThemeProvider>
  );
};

export default App;
