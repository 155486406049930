export const SelectorPotTypes = [
  "Full Time Result",
  "Goal Scorer",
  "Both Teams To Score",
  "Bet Builder",
];

export const PayoutTypeOptions = ["Money", "Free Bets"];

export interface BetBuilderQuestion {
  questionId: number;
  title: string;
  points: number;
  type: string;
  inputType: string;
}

export const BetBuilderQuestions = [
  {
    questionId: 1,
    title: "Both Teams To Score",
    points: 1,
    type: "BOTH_TEAMS_TO_SCORE",
    inputType: "DEFAULT",
  },
  {
    questionId: 2,
    title: "Full Time Result",
    points: 1,
    type: "MATCH_RESULT",
    inputType: "DEFAULT",
  },
  {
    questionId: 4,
    title: "Anytime Goal Scorer",
    points: 4,
    type: "ANYTIME_GOAL_SCORER",
    inputType: "PLAYER",
  },
  {
    questionId: 5,
    title: "Anytime player to be carded",
    points: 4,
    type: "ANYTIME_PLAYER_TO_BE_CARDED",
    inputType: "PLAYER",
  },
  {
    questionId: 6,
    title: "Half Time Result",
    points: 3,
    type: "HALF_TIME_RESULT",
    inputType: "DEFAULT",
  },
  {
    questionId: 7,
    title: "Second Half Result",
    points: 3,
    type: "SECOND_HALF_RESULT",
    inputType: "DEFAULT",
  },
  {
    questionId: 9,
    title: "A Goal In Both Halves",
    points: 2,
    type: "GOAL_IN_BOTH_HALVES",
    inputType: "DEFAULT",
  },
  {
    questionId: 10,
    title: "Most Shots On Target",
    points: 2,
    type: "MOST_SHOTS_ON_TARGET",
    inputType: "DEFAULT",
  },
  {
    questionId: 11,
    title: "Most Corners",
    points: 2,
    type: "MOST_CORNERS",
    inputType: "DEFAULT",
  },
  { questionId: 12, title: "Most Cards", points: 2, type: "MOST_CARDS", inputType: "DEFAULT" },
  {
    questionId: 13,
    title: "Over 4.5 Shots On Target",
    points: 2,
    type: "OVER_OR_EQUAL_FIVE_SHOTS_ON_TARGET",
    inputType: "DEFAULT",
  },
  {
    questionId: 14,
    title: "<HomeClub> Over 4.5 Shots On Target",
    points: 2,
    type: "OVER_OR_EQUAL_FIVE_SHOTS_ON_TARGET_HOME",
    inputType: "DEFAULT",
  },
  {
    questionId: 15,
    title: "<AwayClub> Over 4.5 Shots On Target",
    points: 2,
    type: "OVER_OR_EQUAL_FIVE_SHOTS_ON_TARGET_AWAY",
    inputType: "DEFAULT",
  },
  {
    questionId: 16,
    title: "<HomeClub> Over 10.5 Corners",
    points: 2,
    type: "OVER_OR_EQUAL_ELEVEN_CORNERS_HOME",
    inputType: "DEFAULT",
  },
  {
    questionId: 17,
    title: "<AwayClub> Over 10.5 Corners",
    points: 2,
    type: "OVER_OR_EQUAL_ELEVEN_CORNERS_AWAY",
    inputType: "DEFAULT",
  },
  {
    questionId: 18,
    title: "Any Player To Score A Header",
    points: 5,
    type: "ANY_PLAYER_TO_SCORE_HEADER",
    inputType: "DEFAULT",
  },
  {
    questionId: 19,
    title: "Any Player To Score Outside The Box",
    points: 8,
    type: "ANY_PLAYER_TO_SCORE_OUTSIDE_THE_BOX",
    inputType: "DEFAULT",
  },
  {
    questionId: 20,
    title: "<Player> To Have Over 2.5 Shots On Target",
    points: 8,
    type: "OVER_OR_EQUAL_THREE_SHOTS_ON_TARGET_PLAYER",
    inputType: "DEFAULT",
  },
  {
    questionId: 21,
    title: "Correct Score",
    points: 5,
    type: "CORRECT_SCORE_AND_RESULT",
    inputType: "DEFAULT",
  },
  {
    questionId: 22,
    title: "Over 2.5 Goals",
    points: 2,
    type: "OVER_OR_EQUAL_THREE_GOALS_SCORED",
    inputType: "DEFAULT",
  },
  {
    questionId: 23,
    title: "Over 3.5 Goals",
    points: 2,
    type: "OVER_OR_EQUAL_FOUR_GOALS_SCORED",
    inputType: "DEFAULT",
  },
  {
    questionId: 24,
    title: "Over 4.5 Goals",
    points: 2,
    type: "OVER_OR_EQUAL_FIVE_GOALS_SCORED",
    inputType: "DEFAULT",
  },
  {
    questionId: 25,
    title: "Over 5.5 Goals",
    points: 2,
    type: "OVER_OR_EQUAL_SIX_GOALS_SCORED",
    inputType: "DEFAULT",
  },
  {
    questionId: 26,
    title: "A penalty to be scored?",
    points: 4,
    type: "PENALTY_SCORED",
    inputType: "DEFAULT",
  },
  {
    questionId: 27,
    title: "Over 1.5 Cards",
    points: 2,
    type: "OVER_OR_EQUAL_TWO_CARDS",
    inputType: "DEFAULT",
  },
  {
    questionId: 28,
    title: "Over 2.5 Cards",
    points: 2,
    type: "OVER_OR_EQUAL_THREE_CARDS",
    inputType: "DEFAULT",
  },
  {
    questionId: 29,
    title: "Over 3.5 Cards",
    points: 2,
    type: "OVER_OR_EQUAL_FOUR_CARDS",
    inputType: "DEFAULT",
  },
  {
    questionId: 30,
    title: "Over 4.5 Cards",
    points: 2,
    type: "OVER_OR_EQUAL_FIVE_CARDS",
    inputType: "DEFAULT",
  },
  {
    questionId: 31,
    title: "Over 5.5 Cards",
    points: 2,
    type: "OVER_OR_EQUAL_SIX_CARDS",
    inputType: "DEFAULT",
  },
  {
    questionId: 32,
    title: "A goal to be scored after the 80th minute?",
    points: 2,
    type: "GOAL_SCORED_AFTER_EIGHTY_MINUTES",
    inputType: "DEFAULT",
  },
  {
    questionId: 33,
    title: "Over 1.5 Goals",
    points: 2,
    type: "OVER_OR_EQUAL_TWO_GOALS_SCORED",
    inputType: "DEFAULT",
  },
  {
    questionId: 34,
    title: "A goal to be scored in the first 20 minutes?",
    points: 2,
    type: "GOAL_SCORED_FIRST_TWENTY_MINUTES",
    inputType: "DEFAULT",
  },
  {
    questionId: 35,
    title: "Which team will score first?",
    points: 2,
    type: "TEAM_TO_SCORE_FIRST",
    inputType: "DEFAULT",
  },
  {
    questionId: 36,
    title: "Over 10.5 Corners",
    points: 2,
    type: "OVER_OR_EQUAL_ELEVEN_CORNERS",
    inputType: "DEFAULT",
  },
];

export const oneFixtureSuggestedQuestions = [
  "MATCH_RESULT",
  "BOTH_TEAMS_TO_SCORE",
  "OVER_OR_EQUAL_THREE_GOALS_SCORED",
  "TEAM_TO_SCORE_FIRST",
  "GOAL_IN_BOTH_HALVES",
  "GOAL_SCORED_FIRST_TWENTY_MINUTES",
  "GOAL_SCORED_AFTER_EIGHTY_MINUTES",
  "ANYTIME_GOAL_SCORER",
  "ANY_PLAYER_TO_SCORE_OUTSIDE_THE_BOX",
  "ANY_PLAYER_TO_SCORE_HEADER",
  "ANYTIME_PLAYER_TO_BE_CARDED",
  "MOST_CARDS",
];

export const twoFixtureSuggestedQuestions = [
  "MATCH_RESULT",
  "BOTH_TEAMS_TO_SCORE",
  "OVER_OR_EQUAL_THREE_GOALS_SCORED",
  "TEAM_TO_SCORE_FIRST",
  "ANYTIME_GOAL_SCORER",
  "ANYTIME_PLAYER_TO_BE_CARDED",
  "MOST_CARDS",
];

export const threeFixtureSuggestedQuestions = [
  "MATCH_RESULT",
  "BOTH_TEAMS_TO_SCORE",
  "OVER_OR_EQUAL_THREE_GOALS_SCORED",
  "ANYTIME_GOAL_SCORER",
  "ANYTIME_PLAYER_TO_BE_CARDED",
];

export const fourFixtureSuggestedQuestions = [
  "MATCH_RESULT",
  "BOTH_TEAMS_TO_SCORE",
  "ANYTIME_GOAL_SCORER",
  "ANYTIME_PLAYER_TO_BE_CARDED",
];
