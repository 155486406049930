import { useState } from "react";

import getFixtures from "../../api/PotBuilder/getFixtures";
import { FormErrors } from "../../config/Text";
import type { Competition, Fixture } from "../../interfaces/Fixture";

interface UseFixturesTableReturn {
  fixtureData: Competition[];
  checkedTeamId: number[];
  fixtureIds: number[];
  fixtureError: string;
  handleFixtureClick: () => void;
  handleFixtureClickAway: () => void;
  handleFixtureChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadFixtures: () => Promise<void>;
}

const useFixturesTable = (): UseFixturesTableReturn => {
  const [fixtureData, setFixtureData] = useState<Competition[]>([{ name: "", fixtures: [] }]);
  const [checkedTeamId, setCheckedTeamId] = useState<number[]>([]);
  const [fixtureIds, setFixtureIds] = useState<number[]>([]);
  const [fixtureError, setFixtureError] = useState<string>("");
  const [fixtureClicked, setFixtureClicked] = useState<boolean | null>();
  const [fullFixtureInfoArray, setFullFixtureInfoArray] = useState<Fixture[]>([]);

  // Handlers
  const handleFixtureClick = () => {
    setFixtureClicked(true);
  };

  const handleFixtureClickAway = () => {
    if (fixtureIds.length === 0 && fixtureClicked === true) {
      setFixtureError(FormErrors.fixture);
    }
  };

  const handleFixtureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fixtureId = Number(event.target.value);
    const teamId = Number(event.target.name);

    let updatedCheckedTeamId = [...checkedTeamId];
    let updatedFixtureIds = [...fixtureIds];
    let updatedFullFixtureInfoArray = [...fullFixtureInfoArray];

    if (event.target.checked) {
      updatedCheckedTeamId.push(teamId);
      updatedFixtureIds.push(fixtureId);

      const fixtureInfo = fixtureData
        .flatMap((comp) => comp.fixtures)
        .find((fixture) => fixture.fixtureId === fixtureId);

      if (fixtureInfo) {
        updatedFullFixtureInfoArray.push(fixtureInfo);
      }
    } else {
      updatedCheckedTeamId = updatedCheckedTeamId.filter((id) => id !== teamId);
      updatedFixtureIds = updatedFixtureIds.filter((id) => id !== fixtureId);
      updatedFullFixtureInfoArray = updatedFullFixtureInfoArray.filter(
        (fixture) => fixture.fixtureId !== fixtureId
      );
    }

    setCheckedTeamId(updatedCheckedTeamId);
    setFixtureIds(updatedFixtureIds);
    setFullFixtureInfoArray(updatedFullFixtureInfoArray);
  };

  const loadFixtures = async () => {
    try {
      const data = await getFixtures();
      if (data) {
        setFixtureData(data);
      }
    } catch (err) {
      setFixtureError("Error loading fixtures");
      console.error("Error loading fixtures:", err);
    }
  };

  return {
    fixtureData,
    checkedTeamId,
    fixtureIds,
    fixtureError,
    handleFixtureClick,
    handleFixtureClickAway,
    handleFixtureChange,
    loadFixtures,
  };
};

export default useFixturesTable;
