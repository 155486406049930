import type { ReactElement } from "react";
import React from "react";

import {
  Box,
  FormControlLabel,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
} from "@mui/material";

interface radioInterface {
  value: string;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  options: Array<string>;
}

const RadioField = ({ value, label, onChange, options }: radioInterface): ReactElement => {
  return (
    <Box sx={{ padding: "16px 0px 16px 0px" }}>
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup
        row
        aria-labelledby="demo-radio-buttons-group"
        name="radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option}
            value={option}
            control={<Radio required />}
            label={option}
          />
        ))}
      </MuiRadioGroup>
    </Box>
  );
};

export default RadioField;
