import type { Dispatch, ReactElement, SyntheticEvent } from "react";
import React, { useEffect, useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Button, Grid } from "@mui/material";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

import postVerifiedUserData from "../api/VerifyUser/postVerifiedUserData";
import TextField from "../components/TextField";
import TimeField from "../components/TimeField/TimeField";
import Colors from "../config/Colors";
import {
  AlertTextPot,
  FormErrors,
  verifyUserErrors,
  VerifyUserForm,
  VerifyUserHeaders,
} from "../config/Text";
import { nameValidation, numberValidation } from "../config/Validation";
import type { Address, VerifiedUser } from "../interfaces/VerifiedUser";

import { alertBanner, clearAll, handleClick, handleTextChange } from "./CreateFantasyPot";

const VerifyUserScreen = () => {
  const [username, setUsername] = useState<string>("");
  const [forename, setForename] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(null);
  const [age, setAge] = useState<number>(Infinity);
  const [postcode, setPostcode] = useState<string>("");
  const [thoroughfare, setThoroughfare] = useState<string>("");
  const [buildingNumber, setBuildingNumber] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [townOrCity, setTownOrCity] = useState<string>("");
  const [county, setCounty] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [proofOfIdentityType, setProofOfIdentityType] = useState<string>("");
  const [proofOfAddressType, setProofOfAddressType] = useState<string>("");
  const [verifiedBy, setVerifiedBy] = useState<string>("");

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertErrorMessage, setAlertErrorMessage] = useState<string>("");
  const [progress, setProgress] = useState<number>();

  const [usernameError, setUsernameError] = useState<string>("");
  const [forenameError, setForenameError] = useState<string>("");
  const [lastnameError, setLastnameError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [dateOfBirthError, setDateOfBirthError] = useState<string>("");
  const [postcodeError, setPostcodeError] = useState<string>("");
  const [thoroughfareError, setThoroughfareError] = useState<string>("");
  const [buildingNumberError, setBuildingNumberError] = useState<string>("");
  const [addressLine1Error, setAddressLine1Error] = useState<string>("");
  const [townOrCityError, setTownOrCityError] = useState<string>("");
  const [countyError, setCountyError] = useState<string>("");
  const [districtError, setDistrictError] = useState<string>("");
  const [countryError, setCountryError] = useState<string>("");
  const [proofOfIdentityTypeError, setProofOfIdentityTypeError] = useState<string>("");
  const [proofOfAddressTypeError, setProofOfAddressTypeError] = useState<string>("");
  const [verifiedByError, setVerifiedByError] = useState<string>("");

  const [usernameClicked, setUsernameClicked] = useState<boolean>();
  const [forenameClicked, setForenameClicked] = useState<boolean>();
  const [lastnameClicked, setLastnameClicked] = useState<boolean>();
  const [emailClicked, setEmailClicked] = useState<boolean>();
  const [dateOfBirthClicked, setDateOfBirthClicked] = useState<boolean>();
  const [postcodeClicked, setPostcodeClicked] = useState<boolean>();
  const [thoroughfareClicked, setThoroughfareClicked] = useState<boolean>();
  const [proofOfIdentityTypeClicked, setProofOfIdentityTypeClicked] = useState<boolean>();
  const [proofOfAddressTypeClicked, setProofOfAddressTypeClicked] = useState<boolean>();
  const [verifiedByClicked, setVerifiedByClicked] = useState<boolean>();

  const [verifiedUserData, setVerifiedUserData] = useState<VerifiedUser>();

  useEffect(() => {
    if (username.length === 0 && usernameClicked === true) {
      setUsernameError(FormErrors.text);
    } else {
      setUsernameError("");
    }
  }, [username, usernameClicked]);

  useEffect(() => {
    if (forename.length === 0 && forenameClicked === true) {
      setForenameError(FormErrors.text);
    } else if (forename.match(nameValidation) === null && forename.length !== 0) {
      setForenameError(verifyUserErrors.forename);
    } else {
      setForenameError("");
    }
  }, [forename, forenameClicked]);

  useEffect(() => {
    if (lastname.length === 0 && lastnameClicked === true) {
      setLastnameError(FormErrors.text);
    } else if (lastname.match(nameValidation) === null && lastname.length !== 0) {
      setLastnameError(verifyUserErrors.lastname);
    } else {
      setLastnameError("");
    }
  }, [lastname, lastnameClicked]);

  useEffect(() => {
    if (email.length === 0 && emailClicked === true) {
      setEmailError(FormErrors.text);
    } else {
      setEmailError("");
    }
  }, [email, emailClicked]);

  useEffect(() => {
    const ageOver18 = age != null && age > 18;
    const ageUnder18 = age !== null && age < 18;

    if (ageUnder18) {
      setDateOfBirthError(FormErrors.age);
    } else if (ageOver18) {
      setDateOfBirthError("");
    }
  }, [age]);

  useEffect(() => {
    if (postcode.length === 0 && postcodeClicked === true) {
      setPostcodeError(FormErrors.text);
    } else {
      setPostcodeError("");
    }
  }, [postcode, postcodeClicked]);

  useEffect(() => {
    if (thoroughfare.length === 0 && thoroughfareClicked === true) {
      setThoroughfareError(FormErrors.text);
    } else {
      setThoroughfareError("");
    }
  }, [thoroughfare, thoroughfareClicked]);

  useEffect(() => {
    if (proofOfIdentityType.length === 0 && proofOfIdentityTypeClicked === true) {
      setProofOfIdentityTypeError(FormErrors.text);
    } else {
      setProofOfIdentityTypeError("");
    }
  }, [proofOfIdentityType, proofOfIdentityTypeClicked]);

  useEffect(() => {
    if (proofOfAddressType.length === 0 && proofOfAddressTypeClicked === true) {
      setProofOfAddressTypeError(FormErrors.text);
    } else {
      setProofOfAddressTypeError("");
    }
  }, [proofOfAddressType, proofOfAddressTypeClicked]);

  useEffect(() => {
    if (verifiedBy.length === 0 && verifiedByClicked === true) {
      setVerifiedByError(FormErrors.text);
    } else {
      setVerifiedByError("");
    }
  }, [verifiedBy, verifiedByClicked]);

  useEffect(() => {
    let isError =
      usernameError === "" &&
      forenameError === "" &&
      lastnameError === "" &&
      emailError === "" &&
      dateOfBirthError === "" &&
      postcodeError === "" &&
      thoroughfareError === "" &&
      buildingNumberError === "" &&
      proofOfIdentityTypeError === "" &&
      proofOfAddressTypeError === "" &&
      verifiedByError === "" &&
      username.length !== 0 &&
      forename.length !== 0 &&
      lastname.length !== 0 &&
      email.length !== 0 &&
      dateOfBirth !== null &&
      postcode.length !== 0 &&
      thoroughfare.length !== 0 &&
      proofOfIdentityType.length !== 0 &&
      proofOfAddressType.length !== 0 &&
      verifiedBy.length !== 0;

    setSubmitDisabled(!isError);

    return () => {
      isError = false;
    };
  }, [
    usernameError,
    forenameError,
    lastnameError,
    emailError,
    dateOfBirthError,
    postcodeError,
    thoroughfareError,
    buildingNumberError,
    proofOfIdentityTypeError,
    proofOfAddressType,
    verifiedByError,
    username,
    forename,
    lastname,
    email,
    dateOfBirth,
    postcode,
    thoroughfare,
    proofOfAddressType,
    proofOfIdentityType,
    verifiedBy,
  ]);

  const handleTimeChange = (event: Dayjs | null, setTime: Dispatch<Dayjs | null>) => {
    const time = event;

    CalculateAge(time);
    setTime(time);
  };

  const CalculateAge = (dateOfBirth: Dayjs | null) => {
    const now = dayjs();

    if (dateOfBirth !== null) {
      setAge(now.diff(dateOfBirth, "year"));
    }
  };

  const handleAlertClose = (_event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const submitButton = (): ReactElement => {
    const buttonStyle = {
      width: "100%",
      padding: "8px",
      backgroundColor: submitDisabled ? Colors.Base400 : Colors.Primary600,
      color: Colors.Base050,
    };

    if (buttonLoading === false) {
      return (
        <Button
          disabled={submitDisabled}
          sx={buttonStyle}
          onClick={() => {
            onSubmit();
          }}
        >
          Submit
        </Button>
      );
    } else {
      return (
        <LoadingButton loading variant="outlined" sx={buttonStyle}>
          Submit
        </LoadingButton>
      );
    }
  };

  const onSubmit = async () => {
    setButtonLoading(true);
    const tempAddress: Address = {
      postcode,
      thoroughfare,
      buildingNumber,
      addressLine1,
      townOrCity,
      county,
      district,
      country,
    };

    const dateOfBirthDate = dayjs(dateOfBirth).format("YYYY-MM-DD");

    const tempData: VerifiedUser = {
      username,
      forename,
      lastname,
      email,
      dateOfBirth: dateOfBirthDate,
      address: tempAddress,
      proofOfIdentityType,
      proofOfAddressType,
      verifiedBy,
    };

    setButtonLoading(false);
    try {
      setAlert("progress");
      setProgress(0);
      setAlertOpen(true);
      await postVerifiedUserData(tempData);
      setProgress(100);
      setButtonLoading(false);
      setAlert("success");
      setAlertOpen(true);
    } catch (err) {
      setButtonLoading(false);
      setAlertErrorMessage(AlertTextPot.error);
      setAlert("error");
      setAlertOpen(true);
    }
  };

  return (
    <form>
      {alertBanner(alert, alertOpen, handleAlertClose, progress, alertErrorMessage)}
      <Grid
        container
        spacing={8}
        sx={{
          padding: "32px",
        }}
      >
        <Grid item xs={12} lg={6}>
          <div className={"H5BoldLeftSecondaryPressed"}>{VerifyUserHeaders.userDetails}</div>

          <TextField
            value={username}
            label={VerifyUserForm.username}
            onChange={(event) => {
              handleTextChange(event, null, setUsername, setUsernameError);
            }}
            handleClick={() => {
              handleClick(setUsernameClicked);
            }}
            error={usernameError}
          />
          <TextField
            value={forename}
            label={VerifyUserForm.forename}
            onChange={(event) => {
              handleTextChange(event, nameValidation, setForename, setForenameError);
            }}
            handleClick={() => {
              handleClick(setForenameClicked);
            }}
            error={forenameError}
          />
          <TextField
            value={lastname}
            label={VerifyUserForm.lastname}
            onChange={(event) => {
              handleTextChange(event, nameValidation, setLastname, setLastnameError);
            }}
            handleClick={() => {
              handleClick(setLastnameClicked);
            }}
            error={lastnameError}
          />
          <TextField
            value={email}
            label={VerifyUserForm.email}
            onChange={(event) => {
              handleTextChange(event, null, setEmail, setEmailError);
            }}
            handleClick={() => {
              handleClick(setEmailClicked);
            }}
            error={emailError}
          />
          <TimeField
            value={dateOfBirth}
            label={VerifyUserForm.dateOfBirth}
            onChange={(event) => {
              handleTimeChange(event, setDateOfBirth);
            }}
            handleClick={() => {
              handleClick(setDateOfBirthClicked);
            }}
            dateOnly={true}
            error={dateOfBirthError}
          />
          <div className={"H5BoldLeftSecondaryPressed"}>{VerifyUserHeaders.address}</div>
          <TextField
            value={postcode}
            label={VerifyUserForm.postcode}
            onChange={(event) => {
              handleTextChange(event, null, setPostcode, setPostcodeError, true);
            }}
            handleClick={() => {
              handleClick(setPostcodeClicked);
            }}
            error={postcodeError}
          />
          <TextField
            value={thoroughfare}
            label={VerifyUserForm.thoroughfare}
            onChange={(event) => {
              handleTextChange(event, null, setThoroughfare, setThoroughfareError);
            }}
            handleClick={() => {
              handleClick(setThoroughfareClicked);
            }}
            error={thoroughfareError}
          />
          <TextField
            value={buildingNumber}
            required={false}
            label={VerifyUserForm.buildingNumber}
            onChange={(event) => {
              handleTextChange(event, numberValidation, setBuildingNumber, setBuildingNumberError);
            }}
            error={buildingNumberError}
          />
          <TextField
            value={addressLine1}
            required={false}
            label={VerifyUserForm.addressLine1}
            onChange={(event) => {
              handleTextChange(event, null, setAddressLine1, setAddressLine1Error);
            }}
            error={addressLine1Error}
          />
          <TextField
            value={townOrCity}
            required={false}
            label={VerifyUserForm.townOrCity}
            onChange={(event) => {
              handleTextChange(event, null, setTownOrCity, setTownOrCityError);
            }}
            error={townOrCityError}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            value={county}
            required={false}
            label={VerifyUserForm.county}
            onChange={(event) => {
              handleTextChange(event, null, setCounty, setCountyError);
            }}
            error={countyError}
          />
          <TextField
            value={district}
            required={false}
            label={VerifyUserForm.district}
            onChange={(event) => {
              handleTextChange(event, null, setDistrict, setDistrictError);
            }}
            error={districtError}
          />
          <TextField
            value={country}
            required={false}
            label={VerifyUserForm.country}
            onChange={(event) => {
              handleTextChange(event, null, setCountry, setCountryError);
            }}
            error={countryError}
          />
          <div className={"H5BoldLeftSecondaryPressed"}>{VerifyUserHeaders.proofOfIdentity}</div>
          <TextField
            value={proofOfIdentityType}
            label={VerifyUserForm.proofOfIdentityType}
            onChange={(event) => {
              handleTextChange(event, null, setProofOfIdentityType, setProofOfIdentityTypeError);
            }}
            handleClick={() => {
              handleClick(setProofOfIdentityTypeClicked);
            }}
            error={proofOfIdentityTypeError}
          />
          <TextField
            value={proofOfAddressType}
            label={VerifyUserForm.proofOfAddressType}
            onChange={(event) => {
              handleTextChange(event, null, setProofOfAddressType, setProofOfAddressTypeError);
            }}
            handleClick={() => {
              handleClick(setProofOfAddressTypeClicked);
            }}
            error={proofOfAddressTypeError}
          />
          <TextField
            value={verifiedBy}
            label={VerifyUserForm.verifiedBy}
            onChange={(event) => {
              handleTextChange(event, null, setVerifiedBy, setVerifiedByError);
            }}
            handleClick={() => {
              handleClick(setVerifiedByClicked);
            }}
            error={verifiedByError}
          />
          <br />
          {submitButton()}
          <br />
          <br />
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              padding: "8px",
            }}
            onClick={() => {
              clearAll();
            }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default VerifyUserScreen;
