import { useState } from "react";

const useNewTextField = (
  validationFunction: ((value: string) => string) | null = null,
  initialValue = "",
  onValueChange: ((value: string) => void) | null = null
): [string, string, (newValue: string) => void, () => void] => {
  const [value, setValue] = useState<string>(initialValue);
  const [error, setError] = useState<string>("");

  const handleChange = (newValue: string) => {
    setValue(newValue);
    if (validationFunction) {
      setError(validationFunction(newValue));
    }
    onValueChange?.(newValue);
  };

  const reset = () => {
    setValue(initialValue);
    setError("");
  };

  return [value, error, handleChange, reset];
};

export default useNewTextField;
