import { useCallback, useState } from "react";

import type { Dayjs } from "dayjs";

type TimeFieldValidationFunction = (value: Dayjs | null) => string;

const useTimeField = (
  validationFunction: TimeFieldValidationFunction | null = null,
  initialValue: Dayjs | null = null,
  onValueChange: ((value: Dayjs | null) => void) | null = null
): [Dayjs | null, string, (value: Dayjs | null) => void, () => void] => {
  const [value, setValue] = useState<Dayjs | null>(initialValue);
  const [error, setError] = useState<string>("");

  const handleChange = useCallback(
    (newValue: Dayjs | null) => {
      setValue(newValue);

      if (validationFunction && newValue !== null) {
        setError(validationFunction(newValue));
      } else if (validationFunction && newValue === null) {
        setError(validationFunction(null));
      }

      onValueChange?.(newValue);
    },
    [validationFunction, onValueChange]
  );

  const reset = useCallback(() => {
    setValue(initialValue);
    setError("");
  }, [initialValue]);

  return [value, error, handleChange, reset];
};

export default useTimeField;
