import type { DropDownOption } from "../components/NewDropDownField/NewDropDownField";

export const DefaultBetmojis: DropDownOption[] = [{ label: "Default", value: "" }];

export const LeagueBetmojis = [
  { label: "Premier League", value: ":premierleague:" },
  { label: "Champions League", value: ":championsleague:" },
  { label: "Championship", value: ":championship:" },
  { label: "Europa League", value: ":europa:" },
];

export const PotTypeBetmojis = [
  { label: "Full Time Result", value: ":ftresult:" },
  { label: "Goal Scorer", value: ":goalscorer:" },
  { label: "Both Teams To Score", value: ":btts:" },
  { label: "Builder", value: ":builder:" },
  { label: "Fantasy", value: ":fantasy:" },
  { label: "Survivor", value: ":survivor:" },
];

export const ClubBetmojis: DropDownOption[] = [
  { label: "Arsenal", value: ":crest-1" },
  { label: "Aston Villa", value: ":crest-2" },
  { label: "Brighton", value: ":crest-3" },
  { label: "Burnley", value: ":crest-4" },
  { label: "Chelsea", value: ":crest-5" },
  { label: "Crystal Palace", value: ":crest-6" },
  { label: "Everton", value: ":crest-7" },
  { label: "Fulham", value: ":crest-8" },
  { label: "Leeds", value: ":crest-9" },
  { label: "Leicester", value: ":crest-10" },
  { label: "Liverpool", value: ":crest-11" },
  { label: "Man City", value: ":crest-12" },
  { label: "Man Utd", value: ":crest-13" },
  { label: "Newcastle", value: ":crest-14" },
  { label: "Sheff Utd", value: ":crest-15" },
  { label: "Southampton", value: ":crest-16" },
  { label: "Tottenham", value: ":crest-17" },
  { label: "West Brom", value: ":crest-18" },
  { label: "West Ham", value: ":crest-19" },
  { label: "Wolves", value: ":crest-20" },
  { label: "Albania", value: ":crest-21" },
  { label: "Andorra", value: ":crest-22" },
  { label: "Armenia", value: ":crest-23" },
  { label: "Austria", value: ":crest-24" },
  { label: "Azerbaijan", value: ":crest-25" },
  { label: "Belarus", value: ":crest-26" },
  { label: "Belgium", value: ":crest-27" },
  { label: "Bosnia", value: ":crest-28" },
  { label: "Bulgaria", value: ":crest-29" },
  { label: "Croatia", value: ":crest-30" },
  { label: "Cyprus", value: ":crest-31" },
  { label: "Czech Republic", value: ":crest-32" },
  { label: "Denmark", value: ":crest-33" },
  { label: "England", value: ":crest-34" },
  { label: "Estonia", value: ":crest-35" },
  { label: "Faroe Islands", value: ":crest-36" },
  { label: "Finland", value: ":crest-37" },
  { label: "France", value: ":crest-38" },
  { label: "Georgia", value: ":crest-39" },
  { label: "Germany", value: ":crest-40" },
  { label: "Gibraltar", value: ":crest-41" },
  { label: "Greece", value: ":crest-42" },
  { label: "Hungary", value: ":crest-43" },
  { label: "Iceland", value: ":crest-44" },
  { label: "Israel", value: ":crest-45" },
  { label: "Italy", value: ":crest-46" },
  { label: "Kazakhstan", value: ":crest-47" },
  { label: "Kosovo", value: ":crest-48" },
  { label: "Latvia", value: ":crest-49" },
  { label: "Liechtenstein", value: ":crest-50" },
  { label: "Lithuania", value: ":crest-51" },
  { label: "Luxembourg", value: ":crest-52" },
  { label: "Malta", value: ":crest-53" },
  { label: "Moldova", value: ":crest-54" },
  { label: "Montenegro", value: ":crest-55" },
  { label: "Netherlands", value: ":crest-56" },
  { label: "North Macedonia", value: ":crest-57" },
  { label: "Northern Ireland", value: ":crest-58" },
  { label: "Norway", value: ":crest-59" },
  { label: "Poland", value: ":crest-60" },
  { label: "Portugal", value: ":crest-61" },
  { label: "Rep. Of Ireland", value: ":crest-62" },
  { label: "Romania", value: ":crest-63" },
  { label: "Russia", value: ":crest-64" },
  { label: "San Marino", value: ":crest-65" },
  { label: "Scotland", value: ":crest-66" },
  { label: "Serbia", value: ":crest-67" },
  { label: "Slovakia", value: ":crest-68" },
  { label: "Slovenia", value: ":crest-69" },
  { label: "Spain", value: ":crest-70" },
  { label: "Sweden", value: ":crest-71" },
  { label: "Switzerland", value: ":crest-72" },
  { label: "Turkey", value: ":crest-73" },
  { label: "Ukraine", value: ":crest-74" },
  { label: "Wales", value: ":crest-75" },
  { label: "Norwich", value: ":crest-76" },
  { label: "Watford", value: ":crest-77" },
  { label: "Brentford", value: ":crest-78" },
  { label: "Milan", value: ":crest-79" },
  { label: "Ajax", value: ":crest-80" },
  { label: "Atalanta", value: ":crest-81" },
  { label: "Atletico Madrid", value: ":crest-82" },
  { label: "Barcelona", value: ":crest-83" },
  { label: "Bayern Munich", value: ":crest-84" },
  { label: "Benfica", value: ":crest-85" },
  { label: "Besiktas", value: ":crest-86" },
  { label: "Dortmund", value: ":crest-87" },
  { label: "Club Brugge", value: ":crest-88" },
  { label: "Dynamo Kyiev", value: ":crest-89" },
  { label: "FC Porto", value: ":crest-90" },
  { label: "Inter", value: ":crest-91" },
  { label: "Juventus", value: ":crest-92" },
  { label: "Lille", value: ":crest-93" },
  { label: "Malmo", value: ":crest-94" },
  { label: "PSG", value: ":crest-95" },
  { label: "RB Leipzig", value: ":crest-96" },
  { label: "Real Madrid", value: ":crest-97" },
  { label: "Salzburg", value: ":crest-98" },
  { label: "Sevilla", value: ":crest-99" },
  { label: "Shakhtar Donetsk", value: ":crest-100" },
  { label: "Sheriff", value: ":crest-101" },
  { label: "Sporting Lisbon", value: ":crest-102" },
  { label: "Villarreal", value: ":crest-103" },
  { label: "Wolfsburg", value: ":crest-104" },
  { label: "Young Boys", value: ":crest-105" },
  { label: "Zenit St Petersburg", value: ":crest-106" },
  { label: "Genk", value: ":crest-107" },
  { label: "QPR", value: ":crest-108" },
  { label: "Sunderland", value: ":crest-109" },
  { label: "Preston", value: ":crest-110" },
  { label: "Stoke", value: ":crest-111" },
  { label: "Marseille", value: ":crest-112" },
  { label: "Leverkusen", value: ":crest-113" },
  { label: "Dinamo Zagreb", value: ":crest-114" },
  { label: "Real Betis", value: ":crest-115" },
  { label: "Lazio", value: ":crest-116" },
  { label: "Spartak Moscow", value: ":crest-117" },
  { label: "Rapid Wien", value: ":crest-118" },
  { label: "Legia Warszawa", value: ":crest-119" },
  { label: "Napoli", value: ":crest-120" },
  { label: "Mallorca", value: ":crest-121" },
  { label: "Cadiz", value: ":crest-122" },
  { label: "Osasuna", value: ":crest-123" },
  { label: "Real Sociedad", value: ":crest-124" },
  { label: "Athletic Club", value: ":crest-125" },
  { label: "VfB Stuttgart", value: ":crest-126" },
  { label: "FC Koln", value: ":crest-127" },
  { label: "Empoli", value: ":crest-128" },
  { label: "Udinese", value: ":crest-129" },
  { label: "Salernitana", value: ":crest-130" },
  { label: "Elche", value: ":crest-132" },
  { label: "Valencia", value: ":crest-133" },
  { label: "Alaves", value: ":crest-134" },
  { label: "Celta Vigo", value: ":crest-135" },
  { label: "Espanyol", value: ":crest-136" },
  { label: "Getafe", value: ":crest-137" },
  { label: "Granada CF", value: ":crest-138" },
  { label: "Levante", value: ":crest-139" },
  { label: "Rayo Vallecano", value: ":crest-140" },
  { label: "AS Roma", value: ":crest-141" },
  { label: "Bologna", value: ":crest-142" },
  { label: "Cagliari", value: ":crest-143" },
  { label: "Fiorentina", value: ":crest-144" },
  { label: "Genoa", value: ":crest-145" },
  { label: "Sampdoria", value: ":crest-146" },
  { label: "Sassuolo", value: ":crest-147" },
  { label: "Spezia", value: ":crest-148" },
  { label: "Torino", value: ":crest-149" },
  { label: "Venezia", value: ":crest-150" },
  { label: "Verona", value: ":crest-151" },
  { label: "Celtic", value: ":crest-152" },
  { label: "Rangers", value: ":crest-153" },
  { label: "Hearts", value: ":crest-154" },
  { label: "Motherwell", value: ":crest-155" },
  { label: "Hibernian", value: ":crest-156" },
  { label: "Aberdeen", value: ":crest-157" },
  { label: "Dundee United", value: ":crest-158" },
  { label: "Livingston", value: ":crest-159" },
  { label: "St. Mirren", value: ":crest-160" },
  { label: "Ross County", value: ":crest-161" },
  { label: "Dundee FC", value: ":crest-162" },
  { label: "St. Johnstone", value: ":crest-163" },
  { label: "Middlesbrough", value: ":crest-164" },
  { label: "Nott'm Forest", value: ":crest-165" },
  { label: "Lyon", value: ":crest-166" },
  { label: "Frankfurt", value: ":crest-167" },
  { label: "Huddersfield", value: ":crest-168" },
  { label: "Luton", value: ":crest-169" },
  { label: "Coventry", value: ":crest-170" },
  { label: "Bournemouth", value: ":crest-171" },
  { label: "Birmingham", value: ":crest-172" },
  { label: "BV Borussia 09 Dortmund", value: ":crest-250" },
  { label: "TSV 1860 München", value: ":crest-263" },
  { label: "Blackburn", value: ":crest-264" },
  { label: "Blackpool", value: ":crest-265" },
  { label: "Bristol City", value: ":crest-266" },
  { label: "Cardiff", value: ":crest-267" },
  { label: "Hull City", value: ":crest-268" },
  { label: "Millwall", value: ":crest-269" },
  { label: "Reading", value: ":crest-270" },
  { label: "Rotherham", value: ":crest-271" },
  { label: "Swansea", value: ":crest-272" },
  { label: "Wigan", value: ":crest-273" },
  { label: "København", value: ":crest-274" },
  { label: "Viktoria Plzeň", value: ":crest-275" },
  { label: "Maccabi Haifa", value: ":crest-276" },
  { label: "Sporting CP", value: ":crest-277" },
  { label: "Argentina", value: ":crest-278" },
  { label: "Tunisia", value: ":crest-279" },
  { label: "Senegal", value: ":crest-280" },
  { label: "Saudi Arabia", value: ":crest-281" },
  { label: "Qatar", value: ":crest-282" },
  { label: "Morocco", value: ":crest-283" },
  { label: "Mexico", value: ":crest-284" },
  { label: "Korea Republic", value: ":crest-285" },
  { label: "United States", value: ":crest-286" },
  { label: "Japan", value: ":crest-287" },
  { label: "Ghana", value: ":crest-288" },
  { label: "Ecuador", value: ":crest-289" },
  { label: "Costa Rica", value: ":crest-290" },
  { label: "Canada", value: ":crest-291" },
  { label: "Cameroon", value: ":crest-292" },
  { label: "Brazil", value: ":crest-293" },
  { label: "Australia", value: ":crest-294" },
  { label: "Iran", value: ":crest-295" },
  { label: "Uruguay", value: ":crest-296" },
  { label: "Derby", value: ":crest-297" },
  { label: "Wrexham", value: ":crest-298" },
  { label: "Monaco", value: ":crest-299" },
  { label: "PSV", value: ":crest-300" },
  { label: "Fleetwood Town", value: ":crest-389" },
  { label: "Grimsby Town", value: ":crest-391" },
  { label: "Ipswich", value: ":crest-392" },
  { label: "Plymouth", value: ":crest-393" },
  { label: "Sheff Wed", value: ":crest-394" },
  { label: "Sparta Praha", value: ":crest-395" },
  { label: "Union Berlin", value: ":crest-396" },
  { label: "AEK Athens", value: ":crest-397" },
  { label: "Aris Limassol", value: ":crest-398" },
  { label: "Häcken", value: ":crest-399" },
  { label: "BATE", value: ":crest-401" },
  { label: "Dinamo Tbilisi", value: ":crest-402" },
  { label: "Flora", value: ":crest-403" },
  { label: "Struga", value: ":crest-404" },
  { label: "Swift Hesperange", value: ":crest-405" },
  { label: "Urartu", value: ":crest-406" },
  { label: "FCV Farul Constanţa", value: ":crest-407" },
  { label: "Astana", value: ":crest-408" },
  { label: "Budućnost", value: ":crest-409" },
  { label: "Crvena Zvezda", value: ":crest-410" },
  { label: "TSC", value: ":crest-411" },
  { label: "Žalgiris", value: ":crest-412" },
  { label: "Ferencváros", value: ":crest-413" },
  { label: "Feyenoord", value: ":crest-414" },
  { label: "Galatasaray", value: ":crest-415" },
  { label: "Hamrun Spartans", value: ":crest-416" },
  { label: "HJK", value: ":crest-417" },
  { label: "Zrinjski", value: ":crest-418" },
  { label: "Ballkani", value: ":crest-419" },
  { label: "Partizani Tirana", value: ":crest-420" },
  { label: "Raków Częstochowa", value: ":crest-421" },
  { label: "KÍ", value: ":crest-422" },
  { label: "Larne", value: ":crest-423" },
  { label: "Lincoln Red Imps", value: ":crest-424" },
  { label: "Atlètic Club d'Escaldes", value: ":crest-425" },
  { label: "Molde", value: ":crest-426" },
  { label: "Olimpija", value: ":crest-427" },
  { label: "Ludogorets", value: ":crest-428" },
  { label: "Panathinaikos", value: ":crest-429" },
  { label: "Qarabağ", value: ":crest-430" },
  { label: "Lens", value: ":crest-431" },
  { label: "Antwerp", value: ":crest-432" },
  { label: "Dnipro-1", value: ":crest-433" },
  { label: "Tre Penne", value: ":crest-434" },
  { label: "Servette", value: ":crest-435" },
  { label: "Shamrock Rovers", value: ":crest-436" },
  { label: "The New Saints", value: ":crest-437" },
  { label: "Slovan Bratislava", value: ":crest-438" },
  { label: "Sporting Braga", value: ":crest-439" },
  { label: "AFC Wimbledon", value: ":crest-440" },
  { label: "Accrington Stanley", value: ":crest-441" },
  { label: "Bristol Rovers", value: ":crest-442" },
  { label: "Burton Albion", value: ":crest-443" },
  { label: "Cambridge United", value: ":crest-444" },
  { label: "Carlisle United", value: ":crest-445" },
  { label: "Charlton Athletic", value: ":crest-446" },
  { label: "Cheltenham Town", value: ":crest-447" },
  { label: "Colchester United", value: ":crest-448" },
  { label: "Crawley Town", value: ":crest-449" },
  { label: "Crewe Alexandra", value: ":crest-450" },
  { label: "Doncaster Rovers", value: ":crest-451" },
  { label: "Exeter City", value: ":crest-452" },
  { label: "Forest Green Rovers", value: ":crest-453" },
  { label: "Gillingham", value: ":crest-454" },
  { label: "Harrogate Town", value: ":crest-455" },
  { label: "Leyton Orient", value: ":crest-456" },
  { label: "Lincoln City", value: ":crest-457" },
  { label: "Mansfield Town", value: ":crest-458" },
  { label: "Morecambe", value: ":crest-459" },
  { label: "Notts County", value: ":crest-460" },
  { label: "Oxford Utd", value: ":crest-461" },
  { label: "Peterborough United", value: ":crest-462" },
  { label: "Port Vale", value: ":crest-463" },
  { label: "Shrewsbury Town", value: ":crest-464" },
  { label: "Stevenage", value: ":crest-465" },
  { label: "Sutton United", value: ":crest-466" },
  { label: "Tranmere Rovers", value: ":crest-467" },
  { label: "Wycombe Wanderers", value: ":crest-468" },
  { label: "Bradford City", value: ":crest-469" },
  { label: "Salford City", value: ":crest-470" },
  { label: "Ramsgate", value: ":crest-471" },
  { label: "1874 Northwich", value: ":crest-472" },
  { label: "AFC Croydon Athletic", value: ":crest-473" },
  { label: "AFC Dunstable", value: ":crest-474" },
  { label: "Fylde", value: ":crest-475" },
  { label: "AFC Kempston Rovers", value: ":crest-476" },
  { label: "AFC Liverpool", value: ":crest-477" },
  { label: "AFC Portchester", value: ":crest-478" },
  { label: "AFC Rushden & Diamonds", value: ":crest-479" },
  { label: "AFC Stoneham", value: ":crest-480" },
  { label: "AFC Sudbury", value: ":crest-481" },
  { label: "AFC Telford United", value: ":crest-482" },
  { label: "Uckfield Town", value: ":crest-483" },
  { label: "Varndeanians", value: ":crest-484" },
  { label: "AFC Wulfrunians", value: ":crest-485" },
  { label: "Abbey Rangers", value: ":crest-486" },
  { label: "Albion Sports", value: ":crest-487" },
  { label: "Aldershot Town", value: ":crest-488" },
  { label: "Alfreton Town", value: ":crest-489" },
  { label: "Alton", value: ":crest-490" },
  { label: "Altrincham", value: ":crest-491" },
  { label: "Alvechurch", value: ":crest-492" },
  { label: "Andover New Street", value: ":crest-493" },
  { label: "Anstey Nomads", value: ":crest-494" },
  { label: "Ardley United", value: ":crest-495" },
  { label: "Arlesey Town", value: ":crest-496" },
  { label: "Ascot United", value: ":crest-497" },
  { label: "Ashby Ivanhoe", value: ":crest-498" },
  { label: "Ashford Town (Middlesex)", value: ":crest-499" },
  { label: "Ashford United", value: ":crest-500" },
  { label: "Atherstone Town", value: ":crest-501" },
  { label: "Atherton Collieries", value: ":crest-502" },
  { label: "Athletic Newham", value: ":crest-503" },
  { label: "Avro", value: ":crest-504" },
  { label: "Aylesbury United", value: ":crest-505" },
  { label: "Aylesbury Vale Dynamos", value: ":crest-506" },
  { label: "Aylestone Park", value: ":crest-507" },
  { label: "Badshot Lea", value: ":crest-508" },
  { label: "Baffins Milton Rovers", value: ":crest-509" },
  { label: "Bamber Bridge", value: ":crest-510" },
  { label: "Banbury United", value: ":crest-511" },
  { label: "Barking", value: ":crest-512" },
  { label: "Barnoldswick Town", value: ":crest-513" },
  { label: "Barnstaple Town", value: ":crest-514" },
  { label: "Barton Rovers", value: ":crest-515" },
  { label: "Barton Town", value: ":crest-516" },
  { label: "Barwell", value: ":crest-517" },
  { label: "Basford United", value: ":crest-518" },
  { label: "Bashley", value: ":crest-519" },
  { label: "Basildon United", value: ":crest-520" },
  { label: "Basingstoke Town", value: ":crest-521" },
  { label: "Beaconsfield Town", value: ":crest-522" },
  { label: "Bearsted", value: ":crest-523" },
  { label: "Beckenham Town", value: ":crest-524" },
  { label: "Bedfont Sports", value: ":crest-525" },
  { label: "Bedford Town", value: ":crest-526" },
  { label: "Bedworth United", value: ":crest-527" },
  { label: "Belper United", value: ":crest-528" },
  { label: "Bemerton Heath Harleq.", value: ":crest-529" },
  { label: "Bewdley Town", value: ":crest-530" },
  { label: "Bexhill United", value: ":crest-531" },
  { label: "Bideford", value: ":crest-532" },
  { label: "Biggleswade", value: ":crest-533" },
  { label: "Biggleswade Town", value: ":crest-534" },
  { label: "Biggleswade United", value: ":crest-535" },
  { label: "Billericay Town", value: ":crest-536" },
  { label: "Binfield", value: ":crest-537" },
  { label: "Bishop Auckland", value: ":crest-538" },
  { label: "Bishop's Cleeve", value: ":crest-539" },
  { label: "Bishop's Stortford", value: ":crest-540" },
  { label: "Blackfield & Langley", value: ":crest-541" },
  { label: "Blyth Spartans", value: ":crest-542" },
  { label: "Bognor Regis Town", value: ":crest-543" },
  { label: "Boldmere St. Michaels", value: ":crest-544" },
  { label: "Bootle", value: ":crest-545" },
  { label: "Boreham Wood", value: ":crest-546" },
  { label: "Boro Rangers", value: ":crest-547" },
  { label: "Boston Town", value: ":crest-548" },
  { label: "Boston United", value: ":crest-549" },
  { label: "Bottesford Town", value: ":crest-550" },
  { label: "Bournemouth FC", value: ":crest-551" },
  { label: "Bowers & Pitsea", value: ":crest-552" },
  { label: "Bracknell Town", value: ":crest-553" },
  { label: "Bradford (Park Avenue)", value: ":crest-554" },
  { label: "Bridgwater United", value: ":crest-555" },
  { label: "Bridlington Town", value: ":crest-556" },
  { label: "Brighouse Town", value: ":crest-557" },
  { label: "Brightlingsea Regent", value: ":crest-558" },
  { label: "Brislington", value: ":crest-559" },
  { label: "Bristol Manor Farm", value: ":crest-560" },
  { label: "Brixham", value: ":crest-561" },
  { label: "Broadbridge Heath", value: ":crest-562" },
  { label: "Broadfields United", value: ":crest-563" },
  { label: "Brockenhurst", value: ":crest-564" },
  { label: "Bromley", value: ":crest-565" },
  { label: "Bromsgrove Sporting", value: ":crest-566" },
  { label: "Buckhurst Hill", value: ":crest-567" },
  { label: "Buckland Athletic", value: ":crest-568" },
  { label: "Bugbrooke St Michaels", value: ":crest-569" },
  { label: "Burnham", value: ":crest-570" },
  { label: "Burscough", value: ":crest-571" },
  { label: "Bury AFC", value: ":crest-572" },
  { label: "Bury", value: ":crest-573" },
  { label: "Buxton", value: ":crest-574" },
  { label: "Camberley Town", value: ":crest-575" },
  { label: "Campion", value: ":crest-576" },
  { label: "Canvey Island", value: ":crest-577" },
  { label: "Carlisle City", value: ":crest-578" },
  { label: "Carshalton Athletic", value: ":crest-579" },
  { label: "Chadderton", value: ":crest-580" },
  { label: "Chalfont St Peter", value: ":crest-581" },
  { label: "Charnock Richard", value: ":crest-582" },
  { label: "Chasetown", value: ":crest-583" },
  { label: "Cheadle Town", value: ":crest-584" },
  { label: "Chertsey Town", value: ":crest-585" },
  { label: "Chesham United", value: ":crest-586" },
  { label: "Cheshunt", value: ":crest-587" },
  { label: "Chester", value: ":crest-588" },
  { label: "Chesterfield", value: ":crest-589" },
  { label: "Chichester City", value: ":crest-590" },
  { label: "Chippenham Town", value: ":crest-591" },
  { label: "Chipstead", value: ":crest-592" },
  { label: "Chorley", value: ":crest-593" },
  { label: "Christchurch", value: ":crest-594" },
  { label: "Cinderford Town", value: ":crest-595" },
  { label: "Cirencester Town", value: ":crest-596" },
  { label: "Cleethorpes Town", value: ":crest-597" },
  { label: "Clevedon Town", value: ":crest-598" },
  { label: "Clitheroe", value: ":crest-599" },
  { label: "Coalville Town", value: ":crest-600" },
  { label: "Cobham", value: ":crest-601" },
  { label: "Cockfosters", value: ":crest-602" },
  { label: "Cogenhoe United", value: ":crest-603" },
  { label: "Coggeshall Town", value: ":crest-604" },
  { label: "Coleshill Town", value: ":crest-605" },
  { label: "Colliers Wood United", value: ":crest-606" },
  { label: "Colne", value: ":crest-607" },
  { label: "Colney Heath", value: ":crest-608" },
  { label: "Concord Rangers", value: ":crest-609" },
  { label: "Congleton Town", value: ":crest-610" },
  { label: "Consett", value: ":crest-611" },
  { label: "Corby Town", value: ":crest-612" },
  { label: "Corinthian", value: ":crest-613" },
  { label: "Corinthian-Casuals", value: ":crest-614" },
  { label: "Corsham Town", value: ":crest-615" },
  { label: "Coventry Sphinx", value: ":crest-616" },
  { label: "Coventry United", value: ":crest-617" },
  { label: "Cowes Sports", value: ":crest-618" },
  { label: "Crawley Green", value: ":crest-619" },
  { label: "Cray Valley PM", value: ":crest-620" },
  { label: "Cribbs", value: ":crest-621" },
  { label: "Crook Town AFC", value: ":crest-622" },
  { label: "Crowborough Athletic", value: ":crest-623" },
  { label: "Curzon Ashton", value: ":crest-624" },
  { label: "Dagenham & Redbridge", value: ":crest-625" },
  { label: "Darlaston Town", value: ":crest-626" },
  { label: "Darlington", value: ":crest-627" },
  { label: "Dartford", value: ":crest-628" },
  { label: "Daventry Town", value: ":crest-629" },
  { label: "Deal Town", value: ":crest-630" },
  { label: "Deeping Rangers", value: ":crest-631" },
  { label: "Desborough Town", value: ":crest-632" },
  { label: "Didcot Town", value: ":crest-633" },
  { label: "Dorchester Town", value: ":crest-634" },
  { label: "Dorking Wanderers", value: ":crest-635" },
  { label: "Dover Athletic", value: ":crest-636" },
  { label: "Downham Town", value: ":crest-637" },
  { label: "Dudley Town", value: ":crest-638" },
  { label: "Dulwich Hamlet", value: ":crest-639" },
  { label: "Dunston UTS", value: ":crest-640" },
  { label: "Easington Sports", value: ":crest-641" },
  { label: "East Grinstead Town", value: ":crest-642" },
  { label: "East Thurrock United", value: ":crest-643" },
  { label: "Eastbourne Borough", value: ":crest-644" },
  { label: "Eastbourne Town", value: ":crest-645" },
  { label: "Eastbourne United", value: ":crest-646" },
  { label: "Eastleigh", value: ":crest-647" },
  { label: "Eastwood Community", value: ":crest-648" },
  { label: "Ebbsfleet United", value: ":crest-649" },
  { label: "Eccleshill United", value: ":crest-650" },
  { label: "Edgware & Kingsbury", value: ":crest-651" },
  { label: "Egham Town", value: ":crest-652" },
  { label: "Ely City", value: ":crest-653" },
  { label: "Emley AFC", value: ":crest-654" },
  { label: "Enfield", value: ":crest-655" },
  { label: "Enfield Town", value: ":crest-656" },
  { label: "Epsom & Ewell", value: ":crest-657" },
  { label: "Erith Town", value: ":crest-658" },
  { label: "Erith & Belvedere", value: ":crest-659" },
  { label: "Evesham United", value: ":crest-660" },
  { label: "Exmouth", value: ":crest-661" },
  { label: "Eynesbury Rovers", value: ":crest-662" },
  { label: "Clacton", value: ":crest-663" },
  { label: "Halifax Town", value: ":crest-664" },
  { label: "FC Romania", value: ":crest-665" },
  { label: "United of Manchester", value: ":crest-666" },
  { label: "Fairford Town", value: ":crest-667" },
  { label: "Fakenham Town", value: ":crest-668" },
  { label: "Falmouth Town", value: ":crest-669" },
  { label: "Fareham Town", value: ":crest-670" },
  { label: "Farnham Town", value: ":crest-671" },
  { label: "Farsley Celtic", value: ":crest-672" },
  { label: "Faversham Town", value: ":crest-673" },
  { label: "Felixstowe & Walton Utd", value: ":crest-674" },
  { label: "Fisher", value: ":crest-675" },
  { label: "Flackwell Heath", value: ":crest-676" },
  { label: "Fleet Town", value: ":crest-677" },
  { label: "Folkestone Invicta", value: ":crest-678" },
  { label: "Frenford", value: ":crest-679" },
  { label: "Frickley Athletic", value: ":crest-680" },
  { label: "Gainsborough Trinity", value: ":crest-681" },
  { label: "Garforth Town", value: ":crest-682" },
  { label: "Gateshead", value: ":crest-683" },
  { label: "Glebe", value: ":crest-684" },
  { label: "Glossop North End", value: ":crest-685" },
  { label: "Gloucester City", value: ":crest-686" },
  { label: "Godmanchester Rovers", value: ":crest-687" },
  { label: "Golcar United", value: ":crest-688" },
  { label: "Goole", value: ":crest-689" },
  { label: "Gorleston", value: ":crest-690" },
  { label: "Gosport Borough", value: ":crest-691" },
  { label: "Grantham Town", value: ":crest-692" },
  { label: "Great Wakering Rovers", value: ":crest-693" },
  { label: "Gresley Rovers", value: ":crest-694" },
  { label: "Grimsby Borough", value: ":crest-695" },
  { label: "Guildford City", value: ":crest-696" },
  { label: "Guisborough Town", value: ":crest-697" },
  { label: "Guiseley", value: ":crest-698" },
  { label: "Hadleigh United", value: ":crest-699" },
  { label: "Hadley", value: ":crest-700" },
  { label: "Halesowen Town", value: ":crest-701" },
  { label: "Hallam", value: ":crest-702" },
  { label: "Halstead Town", value: ":crest-703" },
  { label: "Hampton & Richmond", value: ":crest-704" },
  { label: "Hamworthy United", value: ":crest-705" },
  { label: "Handsworth", value: ":crest-706" },
  { label: "Hanley Town", value: ":crest-707" },
  { label: "Hanwell Town", value: ":crest-708" },
  { label: "Hanworth Villa", value: ":crest-709" },
  { label: "Harborough Town", value: ":crest-710" },
  { label: "Harefield United", value: ":crest-711" },
  { label: "Haringey Borough", value: ":crest-712" },
  { label: "Harpenden Town", value: ":crest-713" },
  { label: "Hartley Wintney", value: ":crest-714" },
  { label: "Hartpury University", value: ":crest-715" },
  { label: "Hashtag United", value: ":crest-716" },
  { label: "Hassocks", value: ":crest-717" },
  { label: "Hastings United", value: ":crest-718" },
  { label: "Havant & Waterlooville", value: ":crest-719" },
  { label: "Hayes & Yeading United", value: ":crest-720" },
  { label: "Haywards Heath Town", value: ":crest-721" },
  { label: "Heanor Town", value: ":crest-722" },
  { label: "Heaton Stannington", value: ":crest-723" },
  { label: "Hebburn Town", value: ":crest-724" },
  { label: "Hednesford Town", value: ":crest-725" },
  { label: "Helston Athletic", value: ":crest-726" },
  { label: "Hemel Hempstead Town", value: ":crest-727" },
  { label: "Hendon", value: ":crest-728" },
  { label: "Hereford", value: ":crest-729" },
  { label: "Hereford Lads Club", value: ":crest-730" },
  { label: "Hereford Pegasus", value: ":crest-731" },
  { label: "Herne Bay", value: ":crest-732" },
  { label: "Hertford Town", value: ":crest-733" },
  { label: "Heybridge Swifts", value: ":crest-734" },
  { label: "Highgate United", value: ":crest-735" },
  { label: "Highworth Town", value: ":crest-736" },
  { label: "Hilltop", value: ":crest-737" },
  { label: "Hinckley Leicester Road", value: ":crest-738" },
  { label: "Histon", value: ":crest-739" },
  { label: "Hitchin Town", value: ":crest-740" },
  { label: "Hollands & Blair", value: ":crest-741" },
  { label: "Holmesdale", value: ":crest-742" },
  { label: "Holyport", value: ":crest-743" },
  { label: "Horley Town", value: ":crest-744" },
  { label: "Hornchurch", value: ":crest-745" },
  { label: "Horndean", value: ":crest-746" },
  { label: "Horsham", value: ":crest-747" },
  { label: "Horsham YMCA", value: ":crest-748" },
  { label: "Hucknall Town", value: ":crest-749" },
  { label: "Hullbridge Sports", value: ":crest-750" },
  { label: "Hyde United", value: ":crest-751" },
  { label: "Hythe & Dibden", value: ":crest-752" },
  { label: "Hythe Town", value: ":crest-753" },
  { label: "Ilford", value: ":crest-754" },
  { label: "Ilfracombe Town", value: ":crest-755" },
  { label: "Ilkeston Town", value: ":crest-756" },
  { label: "Ipswich Wanderers", value: ":crest-757" },
  { label: "Jersey Bulls", value: ":crest-758" },
  { label: "Kendal Town", value: ":crest-759" },
  { label: "Kennington", value: ":crest-760" },
  { label: "Kettering Town", value: ":crest-761" },
  { label: "Kidderminster Harriers", value: ":crest-762" },
  { label: "Kidlington", value: ":crest-763" },
  { label: "Kidsgrove Athletic", value: ":crest-764" },
  { label: "Kimberley Miners Welfare", value: ":crest-765" },
  { label: "King's Lynn Town", value: ":crest-766" },
  { label: "Kings Langley", value: ":crest-767" },
  { label: "Kingstonian", value: ":crest-768" },
  { label: "Kirkley & Pakefield", value: ":crest-769" },
  { label: "Knaphill", value: ":crest-770" },
  { label: "Knaresborough Town", value: ":crest-771" },
  { label: "Lakenheath", value: ":crest-772" },
  { label: "Lancaster City", value: ":crest-773" },
  { label: "Lancing", value: ":crest-774" },
  { label: "Larkhall Athletic", value: ":crest-775" },
  { label: "Laverstock & Ford", value: ":crest-776" },
  { label: "Leamington", value: ":crest-777" },
  { label: "Leatherhead", value: ":crest-778" },
  { label: "Leicester Nirvana", value: ":crest-779" },
  { label: "Leverstock Green", value: ":crest-780" },
  { label: "Lewes", value: ":crest-781" },
  { label: "Lichfield City", value: ":crest-782" },
  { label: "Lingfield", value: ":crest-783" },
  { label: "Liskeard Athletic", value: ":crest-784" },
  { label: "Little Common", value: ":crest-785" },
  { label: "Little Oakley", value: ":crest-786" },
  { label: "Littlehampton Town", value: ":crest-787" },
  { label: "Liversedge", value: ":crest-788" },
  { label: "London Lions", value: ":crest-789" },
  { label: "Long Eaton United", value: ":crest-790" },
  { label: "Long Melford", value: ":crest-791" },
  { label: "Longlevens", value: ":crest-792" },
  { label: "Longridge Town", value: ":crest-793" },
  { label: "Lordswood", value: ":crest-794" },
  { label: "Loughborough Dynamo", value: ":crest-795" },
  { label: "Loughborough University", value: ":crest-796" },
  { label: "Lower Breck", value: ":crest-797" },
  { label: "Lowestoft Town", value: ":crest-798" },
  { label: "Loxwood", value: ":crest-799" },
  { label: "Lydd Town", value: ":crest-800" },
  { label: "Lydney Town", value: ":crest-801" },
  { label: "Lye Town", value: ":crest-802" },
  { label: "Lymington Town", value: ":crest-803" },
  { label: "Macclesfield", value: ":crest-804" },
  { label: "Maidenhead United", value: ":crest-805" },
  { label: "Maidstone United", value: ":crest-806" },
  { label: "Maltby Main", value: ":crest-807" },
  { label: "Malvern Town", value: ":crest-808" },
  { label: "Mangotsfield United", value: ":crest-809" },
  { label: "March Town United", value: ":crest-810" },
  { label: "Marine", value: ":crest-811" },
  { label: "Marske United", value: ":crest-812" },
  { label: "Matlock Town", value: ":crest-813" },
  { label: "Melksham Town", value: ":crest-814" },
  { label: "Merstham", value: ":crest-815" },
  { label: "Merthyr Town", value: ":crest-816" },
  { label: "Metropolitan Police", value: ":crest-817" },
  { label: "Mickleover", value: ":crest-818" },
  { label: "Midhurst & Easebourne", value: ":crest-819" },
  { label: "Mildenhall Town", value: ":crest-820" },
  { label: "Milton Keynes Irish", value: ":crest-821" },
  { label: "Moneyfields", value: ":crest-822" },
  { label: "Morpeth Town", value: ":crest-823" },
  { label: "Mossley", value: ":crest-824" },
  { label: "Mousehole", value: ":crest-825" },
  { label: "Mulbarton Wanderers", value: ":crest-826" },
  { label: "Nailsea & Tickenham", value: ":crest-827" },
  { label: "Nantwich Town", value: ":crest-828" },
  { label: "Needham Market", value: ":crest-829" },
  { label: "New Salamis", value: ":crest-830" },
  { label: "Newark and Sherwood FC", value: ":crest-831" },
  { label: "Newcastle Benfield", value: ":crest-832" },
  { label: "Newcastle Town", value: ":crest-833" },
  { label: "Newhaven", value: ":crest-834" },
  { label: "Newmarket Town", value: ":crest-835" },
  { label: "Newport Pagnell Town", value: ":crest-836" },
  { label: "Newton Aycliffe", value: ":crest-837" },
  { label: "North Ferriby", value: ":crest-838" },
  { label: "North Greenford United", value: ":crest-839" },
  { label: "North Leigh", value: ":crest-840" },
  { label: "North Shields", value: ":crest-841" },
  { label: "Northallerton Town", value: ":crest-842" },
  { label: "Northwich Victoria", value: ":crest-843" },
  { label: "Northwood", value: ":crest-844" },
  { label: "Norwich United", value: ":crest-845" },
  { label: "Nuneaton Borough", value: ":crest-846" },
  { label: "Oadby Town", value: ":crest-847" },
  { label: "Okehampton Argyle", value: ":crest-848" },
  { label: "Oldham Athletic", value: ":crest-849" },
  { label: "Oldland Abbotonians", value: ":crest-850" },
  { label: "Ossett United", value: ":crest-851" },
  { label: "Padiham", value: ":crest-852" },
  { label: "Pagham", value: ":crest-853" },
  { label: "Paulton Rovers", value: ":crest-854" },
  { label: "Peacehaven & Telscombe", value: ":crest-855" },
  { label: "Penistone Church", value: ":crest-856" },
  { label: "Penrith AFC", value: ":crest-857" },
  { label: "Peterborough Sports", value: ":crest-858" },
  { label: "Petersfield Town", value: ":crest-859" },
  { label: "Phoenix Sports", value: ":crest-860" },
  { label: "Pickering Town", value: ":crest-861" },
  { label: "Pilkington", value: ":crest-862" },
  { label: "Pinchbeck United", value: ":crest-863" },
  { label: "Plymouth Parkway", value: ":crest-864" },
  { label: "Pontefract Collieries", value: ":crest-865" },
  { label: "Poole Town", value: ":crest-866" },
  { label: "Portland United", value: ":crest-867" },
  { label: "Potters Bar Town", value: ":crest-868" },
  { label: "Potton United", value: ":crest-869" },
  { label: "Prescot Cables", value: ":crest-870" },
  { label: "Prestwich Heys", value: ":crest-871" },
  { label: "Punjab United", value: ":crest-872" },
  { label: "Quorn", value: ":crest-873" },
  { label: "Racing Club Warwick", value: ":crest-874" },
  { label: "Radcliffe", value: ":crest-875" },
  { label: "Ramsbottom United", value: ":crest-876" },
  { label: "Raynes Park Vale", value: ":crest-877" },
  { label: "Reading City", value: ":crest-878" },
  { label: "Real Bedford", value: ":crest-879" },
  { label: "Redbridge", value: ":crest-880" },
  { label: "Redcar Athletic", value: ":crest-881" },
  { label: "Redditch United", value: ":crest-882" },
  { label: "Redhill", value: ":crest-883" },
  { label: "Risborough Rangers", value: ":crest-884" },
  { label: "Rochdale", value: ":crest-885" },
  { label: "Roman Glass St George", value: ":crest-886" },
  { label: "Romford", value: ":crest-887" },
  { label: "Rossington Main", value: ":crest-888" },
  { label: "Royal Wootton Bassett", value: ":crest-889" },
  { label: "Royston Town", value: ":crest-890" },
  { label: "Rugby Borough", value: ":crest-891" },
  { label: "Rugby Town", value: ":crest-892" },
  { label: "Rushall Olympic", value: ":crest-893" },
  { label: "Rusthall", value: ":crest-894" },
  { label: "Saffron Walden Town", value: ":crest-895" },
  { label: "Saltash United", value: ":crest-896" },
  { label: "Saltdean United", value: ":crest-897" },
  { label: "Sandhurst Town", value: ":crest-898" },
  { label: "Sawbridgeworth Town", value: ":crest-899" },
  { label: "Scarborough Athletic", value: ":crest-900" },
  { label: "Scunthorpe United", value: ":crest-901" },
  { label: "Seaham Red Star", value: ":crest-902" },
  { label: "Shaftesbury Town", value: ":crest-903" },
  { label: "Sheffield", value: ":crest-904" },
  { label: "Shefford Town & Campton", value: ":crest-905" },
  { label: "Sheppey United", value: ":crest-906" },
  { label: "Shepshed Dynamo", value: ":crest-907" },
  { label: "Shepton Mallet", value: ":crest-908" },
  { label: "Sherborne Town", value: ":crest-909" },
  { label: "Sheringham", value: ":crest-910" },
  { label: "Shifnal Town", value: ":crest-911" },
  { label: "Shildon AFC", value: ":crest-912" },
  { label: "Sholing", value: ":crest-913" },
  { label: "Shoreham", value: ":crest-914" },
  { label: "Silsden", value: ":crest-915" },
  { label: "Sittingbourne", value: ":crest-916" },
  { label: "Skegness Town", value: ":crest-917" },
  { label: "Skelmersdale United", value: ":crest-918" },
  { label: "Sleaford Town", value: ":crest-919" },
  { label: "Slimbridge", value: ":crest-920" },
  { label: "Snodland Town", value: ":crest-921" },
  { label: "Soham Town Rangers", value: ":crest-922" },
  { label: "Solihull Moors", value: ":crest-923" },
  { label: "South Park", value: ":crest-924" },
  { label: "South Shields", value: ":crest-925" },
  { label: "Spalding United", value: ":crest-926" },
  { label: "Spelthorne Sports", value: ":crest-927" },
  { label: "Spennymoor Town", value: ":crest-928" },
  { label: "Sporting Bengal United", value: ":crest-929" },
  { label: "Sporting Khalsa", value: ":crest-930" },
  { label: "Squires Gate", value: ":crest-931" },
  { label: "St Blazey", value: ":crest-932" },
  { label: "St Ives Town", value: ":crest-933" },
  { label: "St Neots Town", value: ":crest-934" },
  { label: "St. Panteleimon", value: ":crest-935" },
  { label: "Stafford Rangers", value: ":crest-936" },
  { label: "Stalybridge Celtic", value: ":crest-937" },
  { label: "Stamford", value: ":crest-938" },
  { label: "Stansfeld", value: ":crest-939" },
  { label: "Stansted", value: ":crest-940" },
  { label: "Stanway Rovers", value: ":crest-941" },
  { label: "Stockport Town", value: ":crest-942" },
  { label: "Stocksbridge Park Steels", value: ":crest-943" },
  { label: "Stockton Town", value: ":crest-944" },
  { label: "Stone Old Alleynians", value: ":crest-945" },
  { label: "Stotfold", value: ":crest-946" },
  { label: "Stourport Swifts", value: ":crest-947" },
  { label: "Stowmarket Town", value: ":crest-948" },
  { label: "Studley", value: ":crest-949" },
  { label: "Sunderland RCA", value: ":crest-950" },
  { label: "Sutton Athletic", value: ":crest-951" },
  { label: "Sutton Coldfield Town", value: ":crest-952" },
  { label: "Sutton Common Rovers", value: ":crest-953" },
  { label: "Swindon Supermarine", value: ":crest-954" },
  { label: "Tadcaster Albion", value: ":crest-955" },
  { label: "Tadley Calleva", value: ":crest-956" },
  { label: "Takeley", value: ":crest-957" },
  { label: "Tamworth", value: ":crest-958" },
  { label: "Taunton Town", value: ":crest-959" },
  { label: "Tavistock", value: ":crest-960" },
  { label: "Thackley", value: ":crest-961" },
  { label: "Thame United", value: ":crest-962" },
  { label: "Thatcham Town", value: ":crest-963" },
  { label: "Thetford Town", value: ":crest-964" },
  { label: "Thornbury Town", value: ":crest-965" },
  { label: "Three Bridges", value: ":crest-966" },
  { label: "Tilbury", value: ":crest-967" },
  { label: "Tiverton Town", value: ":crest-968" },
  { label: "Tividale", value: ":crest-969" },
  { label: "Tonbridge Angels", value: ":crest-970" },
  { label: "Tooting & Mitcham United", value: ":crest-971" },
  { label: "Tow Law Town", value: ":crest-972" },
  { label: "Trafford", value: ":crest-973" },
  { label: "Tring Athletic", value: ":crest-974" },
  { label: "Truro City", value: ":crest-975" },
  { label: "Tuffley Rovers", value: ":crest-976" },
  { label: "Tunbridge Wells", value: ":crest-977" },
  { label: "United Services", value: ":crest-978" },
  { label: "Uttoxeter Town", value: ":crest-979" },
  { label: "Uxbridge", value: ":crest-980" },
  { label: "VCD Athletic", value: ":crest-981" },
  { label: "Vauxhall Motors", value: ":crest-982" },
  { label: "Virginia Water", value: ":crest-983" },
  { label: "Wallingford & Crowmarsh", value: ":crest-984" },
  { label: "Walsall Wood", value: ":crest-985" },
  { label: "Walsham Le Willows", value: ":crest-986" },
  { label: "Waltham Abbey", value: ":crest-987" },
  { label: "Walthamstow", value: ":crest-988" },
  { label: "Walton & Hersham", value: ":crest-989" },
  { label: "Wantage Town", value: ":crest-990" },
  { label: "Warrington Rylands", value: ":crest-991" },
  { label: "Wealdstone", value: ":crest-992" },
  { label: "Welling Town", value: ":crest-993" },
  { label: "Welling United", value: ":crest-994" },
  { label: "Wellingborough Town", value: ":crest-995" },
  { label: "Wellington AFC", value: ":crest-996" },
  { label: "Wells City", value: ":crest-997" },
  { label: "Welton Rovers", value: ":crest-998" },
  { label: "Welwyn Garden City", value: ":crest-999" },
  { label: "Wembley", value: ":crest-1000" },
  { label: "Wendron United", value: ":crest-1001" },
  { label: "West Allotment Celtic", value: ":crest-1002" },
  { label: "West Didsbury & Chorlton", value: ":crest-1003" },
  { label: "West Essex", value: ":crest-1004" },
  { label: "Westbury United", value: ":crest-1005" },
  { label: "Westfield", value: ":crest-1006" },
  { label: "Westfields", value: ":crest-1007" },
  { label: "Weston-super-Mare", value: ":crest-1008" },
  { label: "Weymouth", value: ":crest-1009" },
  { label: "Whickham", value: ":crest-1010" },
  { label: "Whitby Town", value: ":crest-1011" },
  { label: "Whitchurch Alport", value: ":crest-1012" },
  { label: "White Ensign", value: ":crest-1013" },
  { label: "Whitehawk", value: ":crest-1014" },
  { label: "Whitley Bay", value: ":crest-1015" },
  { label: "Whitstable Town", value: ":crest-1016" },
  { label: "Widnes", value: ":crest-1017" },
  { label: "Willand Rovers", value: ":crest-1018" },
  { label: "Winchester City", value: ":crest-1019" },
  { label: "Wingate & Finchley", value: ":crest-1020" },
  { label: "Winterton Rangers", value: ":crest-1021" },
  { label: "Wisbech Town", value: ":crest-1022" },
  { label: "Witham Town", value: ":crest-1023" },
  { label: "Woking", value: ":crest-1024" },
  { label: "Wokingham & Emmbrook", value: ":crest-1025" },
  { label: "Wolverhampton Casuals", value: ":crest-1026" },
  { label: "Woodbridge Town", value: ":crest-1027" },
  { label: "Woodford Town", value: ":crest-1028" },
  { label: "Worcester City", value: ":crest-1029" },
  { label: "Worcester Raiders", value: ":crest-1030" },
  { label: "Workington", value: ":crest-1031" },
  { label: "Worksop Town", value: ":crest-1032" },
  { label: "Worthing", value: ":crest-1033" },
  { label: "Wroxham", value: ":crest-1034" },
  { label: "Wythenshawe", value: ":crest-1035" },
  { label: "Wythenshawe Town", value: ":crest-1036" },
  { label: "Yate Town", value: ":crest-1037" },
  { label: "Yaxley", value: ":crest-1038" },
  { label: "Yeovil Town", value: ":crest-1039" },
  { label: "York City", value: ":crest-1040" },
  { label: "Bolton Wanderers", value: ":crest-1041" },
  { label: "Newport County", value: ":crest-1042" },
  { label: "Athletic Club", value: ":crest-1043" },
  { label: "Osasuna", value: ":crest-1044" },
  { label: "Cádiz", value: ":crest-1045" },
  { label: "Deportivo Alavés", value: ":crest-1046" },
  { label: "Getafe", value: ":crest-1047" },
  { label: "Girona", value: ":crest-1048" },
  { label: "Granada", value: ":crest-1049" },
  { label: "Rayo Vallecano", value: ":crest-1050" },
  { label: "Real Betis", value: ":crest-1051" },
  { label: "Celta de Vigo", value: ":crest-1052" },
  { label: "Mallorca", value: ":crest-1053" },
  { label: "Almería", value: ":crest-1054" },
  { label: "Las Palmas", value: ":crest-1055" },
  { label: "Valencia", value: ":crest-1056" },
  { label: "Villarreal", value: ":crest-1057" },
  { label: "Monza", value: ":crest-1058" },
  { label: "Fiorentina", value: ":crest-1059" },
  { label: "Roma", value: ":crest-1060" },
  { label: "Atalanta", value: ":crest-1061" },
  { label: "Bologna", value: ":crest-1062" },
  { label: "Cagliari", value: ":crest-1063" },
  { label: "Empoli", value: ":crest-1064" },
  { label: "Frosinone", value: ":crest-1065" },
  { label: "Genoa", value: ":crest-1066" },
  { label: "Hellas Verona", value: ":crest-1067" },
  { label: "Torino", value: ":crest-1068" },
  { label: "Lecce", value: ":crest-1069" },
  { label: "Salernitana", value: ":crest-1070" },
  { label: "Sassuolo", value: ":crest-1071" },
  { label: "Udinese", value: ":crest-1072" },
  { label: "Heidenheim", value: ":crest-1073" },
  { label: "Köln", value: ":crest-1074" },
  { label: "Mainz 05", value: ":crest-1075" },
  { label: "Borussia M'gladbach", value: ":crest-1076" },
  { label: "Augsburg", value: ":crest-1077" },
  { label: "Freiburg", value: ":crest-1078" },
  { label: "Darmstadt 98", value: ":crest-1079" },
  { label: "Werder Bremen", value: ":crest-1080" },
  { label: "Hoffenheim", value: ":crest-1081" },
  { label: "Stuttgart", value: ":crest-1082" },
  { label: "Bochum", value: ":crest-1083" },
  { label: "Wolfsburg", value: ":crest-1084" },
  { label: "Portsmouth", value: ":crest-1085" },
  { label: "Como", value: ":crest-1086" },
  { label: "Parma", value: ":crest-1087" },
  { label: "Venezia", value: ":crest-1088" },
  { label: "Leganés", value: ":crest-1089" },
  { label: "Real Valladolid", value: ":crest-1090" },
  { label: "Espanyol", value: ":crest-1091" },
  { label: "Midtjylland", value: ":crest-1092" },
  { label: "St. Pauli", value: ":crest-1093" },
  { label: "Holstein Kiel", value: ":crest-1094" },
  { label: "Barnsley", value: ":crest-1095" },
  { label: "Brest", value: ":crest-1096" },
  { label: "Sturm Graz", value: ":crest-1097" },
  { label: "Barrow", value: ":crest-1098" },
  { label: "Walsall", value: ":crest-1099" },
  { label: "APOEL", value: ":crest-1100" },
  { label: "AZ", value: ":crest-1101" },
  { label: "Beşiktaş", value: ":crest-1102" },
  { label: "Petrocub", value: ":crest-1103" },
  { label: "Cercle Brugge", value: ":crest-1104" },
  { label: "Dinamo Minsk", value: ":crest-1105" },
  { label: "Lugano", value: ":crest-1106" },
  { label: "Twente", value: ":crest-1107" },
  { label: "Bodø / Glimt", value: ":crest-1108" },
  { label: "Borac Banja Luka", value: ":crest-1109" },
  { label: "Kryvbas KR", value: ":crest-1110" },
  { label: "Panevėžys", value: ":crest-1111" },
  { label: "Partizan", value: ":crest-1112" },
  { label: "Rīgas FS", value: ":crest-1113" },
  { label: "Sheriff", value: ":crest-1114" },
  { label: "Tobol", value: ":crest-1115" },
  { label: "Vojvodina", value: ":crest-1116" },
  { label: "Fenerbahçe", value: ":crest-1117" },
  { label: "Rijeka", value: ":crest-1118" },
  { label: "Hearts", value: ":crest-1119" },
  { label: "Elfsborg", value: ":crest-1120" },
  { label: "Llapi", value: ":crest-1121" },
  { label: "Kilmarnock", value: ":crest-1122" },
  { label: "LASK", value: ":crest-1123" },
  { label: "Ružomberok", value: ":crest-1124" },
  { label: "Maccabi Petah Tikva", value: ":crest-1125" },
  { label: "Maccabi Tel Aviv", value: ":crest-1126" },
  { label: "Celje", value: ":crest-1127" },
  { label: "Maribor", value: ":crest-1128" },
  { label: "Nice", value: ":crest-1129" },
  { label: "Olympiakos Piraeus", value: ":crest-1130" },
  { label: "Olympique Lyonnais", value: ":crest-1131" },
  { label: "PAOK", value: ":crest-1132" },
  { label: "Botev Plovdiv", value: ":crest-1133" },
  { label: "Pafos", value: ":crest-1134" },
  { label: "Paks", value: ":crest-1135" },
  { label: "Anderlecht", value: ":crest-1136" },
  { label: "Union Saint-Gilloise", value: ":crest-1137" },
  { label: "Corvinul Hunedoara", value: ":crest-1138" },
  { label: "FCSB", value: ":crest-1139" },
  { label: "Rapid", value: ":crest-1140" },
  { label: "Slavia Praha", value: ":crest-1141" },
  { label: "Jagiellonia Białystok", value: ":crest-1142" },
  { label: "Silkeborg", value: ":crest-1143" },
  { label: "UE Santa Coloma", value: ":crest-1144" },
  { label: "Trabzonspor", value: ":crest-1145" },
  { label: "Wisła Kraków", value: ":crest-1146" },
  { label: "Zira", value: ":crest-1147" },
  { label: "Stockport County", value: ":crest-1148" },
];

export const SelectorBetmojis: DropDownOption[] = [
  ...DefaultBetmojis,
  ...LeagueBetmojis,
  ...PotTypeBetmojis,
  ...ClubBetmojis,
];
