export const PayoutStructureSuggestions = [
  {
    label: "Pays 1",
    value: "100",
  },
  {
    label: "Pays 3 (60-25-15)",
    value: "60-25-15",
  },
  {
    label: "Pays 3 (60-30-10)",
    value: "60-30-10",
  },
  {
    label: "Pays 5",
    value: "40-22-18-12-8",
  },
  {
    label: "Pays 8 ",
    value: "80-6.8-4.4-2.8-2-1.6-1.4-1",
  },
  {
    label: "Pays 10",
    value: "60-12-8-6-4-3-2.5-2-1.5-1",
  },
  {
    label: "Pays 12",
    value: "30-15-12-10-8-6-5-4-3.5-3-2.5-1",
  },
  {
    label: "Pays 20",
    value: "11.5-9-8.5-8-7.5-7-6.5-6-5.5-5-4.5-4-3.5-3-2.5-2-2-1.5-1.5-1",
  },
];
