import type { ReactElement } from "react";
import React, { useEffect, useState } from "react";

import { Button, Card } from "@mui/material";
import type { Auth0Error } from "auth0-js";
import auth0 from "auth0-js";

import BetmateLogo from "../assets/BetmateLogo";
import TextField from "../components/TextField";
import Colors from "../config/Colors";
import { LoginText } from "../config/Text";

import { handleClick, handleTextChange } from "./CreateFantasyPot";

const LoginScreen = (): ReactElement => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [phoneNumberClicked, setPhoneNumberClicked] = useState<boolean | null>();
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [verificationCodeError, setVerificationCodeError] = useState<string | null>(null);
  const [verificationCodeClicked, setVerificationCodeClicked] = useState<boolean | null>();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [phoneNumberField, setPhoneNumberField] = useState<boolean>(true);

  const numberValidation = /^[0-9]{0,11}$/;
  const codeValidation = /^[0-9]{6}$/;

  const currentUrl = window.location.origin;

  useEffect(() => {
    if (phoneNumber.length < 10 && phoneNumber.length > 11 && phoneNumberClicked) {
      setPhoneNumberError(LoginText.error);
    } else {
      setPhoneNumberError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, phoneNumberClicked]);

  useEffect(() => {
    if (verificationCode.length === 0 && verificationCodeClicked) {
      setVerificationCodeError(LoginText.verificationCodeError);
    } else {
      setVerificationCodeError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode, verificationCodeClicked]);

  useEffect(() => {
    const phoneNumberValid = phoneNumber.length !== 0 && !phoneNumberError;
    const verificationCodeValid = verificationCode.length !== 0 && !verificationCodeError;

    if (phoneNumberValid || verificationCodeValid) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [
    phoneNumber,
    phoneNumberClicked,
    verificationCode,
    verificationCodeClicked,
    verificationCodeError,
    phoneNumberError,
  ]);

  useEffect(() => {
    window.localStorage.removeItem("isLoggedIn");
    window.localStorage.removeItem("loginInfo");
    window.localStorage.removeItem("userInfo");
    window.localStorage.removeItem("sessionTime");
  });

  const webAuth = new auth0.WebAuth({
    clientID: "uEiollaVDURaBp24yWo023fAW24L4zAJ",
    domain: "betmate-cms.uk.auth0.com",
    redirectUri: `${currentUrl}/Capture`,
    responseType: "token",
    audience: "https://betmate-cms.uk.auth0.com/api/v2/",
  });

  const onSubmit = () => {
    if (phoneNumberField) {
      let cleanedPhoneNumber;
      if (phoneNumber.charAt(0) === "0") {
        cleanedPhoneNumber = phoneNumber.replace(/^0/, "+44");
      } else {
        cleanedPhoneNumber = "+44" + phoneNumber;
      }
      setPhoneNumber(cleanedPhoneNumber);
      webAuth.passwordlessStart(
        {
          connection: "sms",
          send: "code",
          phoneNumber: cleanedPhoneNumber,
        },
        (err: Auth0Error | null, res: string) => {
          if (err) {
            console.log(err);
            setPhoneNumberError(LoginText.phoneNumberError);
          }
          if (res) {
            setPhoneNumberField(false);
          }
        }
      );
    } else {
      webAuth.passwordlessLogin(
        {
          connection: "sms",
          phoneNumber: phoneNumber,
          verificationCode: verificationCode,
        },
        (err: Auth0Error | null, res: string) => {
          if (err) {
            setVerificationCodeError(LoginText.verificationCodeError);
          }
          if (res) {
            // onSetInfo();
          }
        }
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
      }}
    >
      <Card
        sx={{
          minWidth: 275,
          minHeight: 275,
          padding: "32px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <BetmateLogo />
        <br />
        <br />
        {phoneNumberField === true ? (
          <TextField
            value={phoneNumber}
            error={phoneNumberError}
            label={LoginText.phoneNumberLabel}
            onChange={(event) => {
              handleTextChange(event, numberValidation, setPhoneNumber, setPhoneNumberError);
            }}
            inputAdornment={LoginText.leftInputAdornment}
            handleClick={() => {
              handleClick(setPhoneNumberClicked);
            }}
          />
        ) : (
          <TextField
            value={verificationCode}
            error={verificationCodeError}
            label={LoginText.verificationCodeLabel}
            onChange={(event) => {
              handleTextChange(
                event,
                codeValidation,
                setVerificationCode,
                setVerificationCodeError
              );
            }}
            handleClick={() => {
              handleClick(setVerificationCodeClicked);
            }}
          />
        )}
        <Button
          disabled={submitDisabled}
          sx={{
            width: "100%",
            padding: "8px",
            backgroundColor: submitDisabled ? Colors.Base400 : Colors.Primary600,
            color: Colors.Base050,
          }}
          onClick={() => {
            onSubmit();
          }}
        >
          Submit
        </Button>
      </Card>
    </div>
  );
};

export default LoginScreen;
