import type { ChangeEvent, ReactElement, SyntheticEvent } from "react";
import React, { useEffect, useState } from "react";

import { LoadingButton } from "@mui/lab";
import type { AlertColor } from "@mui/material";
import { Alert, Button, Grid, LinearProgress, Snackbar } from "@mui/material";
import type { Dayjs } from "dayjs";

import addFreeBets from "../api/FreeBets/addFreeBets";
import Checkbox from "../components/Checkbox";
import TextField from "../components/TextField";
import TimeField from "../components/TimeField/TimeField";
import Colors from "../config/Colors";
import { AlertTextPot, FormErrors, FreeBetErrors, FreeBetsText } from "../config/Text";
import { currencyValidation, nonZeroCurrencyValidation } from "../config/Validation";
import type { FreeBet } from "../interfaces/FreeBet";

import {
  clearAll,
  handleClick,
  handleTextChange,
  handleTextClickAway,
  handleTimeChange,
} from "./CreateFantasyPot";

const FreeBetScreen = () => {
  const [username, setUsername] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [canBeUsedForFantasy, setCanBeUsedForFantasy] = useState<boolean>(true);
  const [canBeUsedForSelector, setCanBeUsedForSelector] = useState<boolean>(true);
  const [canJoinPublicPot, setCanJoinPublicPot] = useState<boolean>(true);
  const [canJoinPrivatePot, setCanJoinPrivatePot] = useState<boolean>(true);
  const [canCreatePrivatePot, setCanCreatePrivatePot] = useState<boolean>(true);
  const [validFromDateTimeUtc, setValidFromDateTimeUtc] = useState<Dayjs | null>(null);
  const [validToDateTimeUtc, setValidToDateTimeUtc] = useState<Dayjs | null>(null);

  const [usernameError, setUsernameError] = useState<string>("");
  const [amountError, setAmountError] = useState<string>("");
  const [validFromDateTimeUtcError, setValidFromDateTimeUtcError] = useState<string>("");
  const [validToDateTimeUtcError, setValidToDateTimeUtcError] = useState<string>("");

  const [usernameClicked, setUsernameClicked] = useState<boolean | null>();
  const [amountClicked, setAmountClicked] = useState<boolean | null>();
  const [validFromDateTimeUtcClicked, setValidFromDateTimeUtcClicked] = useState<boolean | null>();
  const [validToDateTimeUtcClicked, setValidToDateTimeUtcClicked] = useState<boolean | null>();

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertErrorMessage, setAlertErrorMessage] = useState<string>("");
  const [progress, setProgress] = useState<number>();

  useEffect(() => {
    let isError =
      usernameError === "" &&
      amountError === "" &&
      validFromDateTimeUtcError === "" &&
      validToDateTimeUtcError === "" &&
      username.length !== 0 &&
      amount.length !== 0;

    setSubmitDisabled(!isError);

    return () => {
      isError = false;
    };
  }, [
    username,
    amount,
    usernameError,
    amountError,
    validFromDateTimeUtcError,
    validToDateTimeUtcError,
  ]);

  // >3 <16
  useEffect(() => {
    if (username.length === 0 && usernameClicked === true) {
      setUsernameError(FormErrors.text);
    } else {
      setUsernameError("");
    }
  }, [username, usernameClicked]);

  useEffect(() => {
    const amountNumber = Number(amount);

    if (
      (amount.length === 0 && amountClicked === true) ||
      (amount.length !== 0 && (amountNumber > 100 || amountNumber < 1))
    ) {
      setAmountError(FreeBetErrors.amount);
    } else if (
      amount.match(currencyValidation) !== null &&
      amountNumber < 100 &&
      amountNumber > 1
    ) {
      setAmountError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  useEffect(() => {
    let isAfter = true;

    if (
      validFromDateTimeUtc &&
      validToDateTimeUtc &&
      validFromDateTimeUtc.isAfter(validToDateTimeUtc)
    ) {
      isAfter = false;
    }

    if (validFromDateTimeUtc !== null && isAfter === false) {
      setValidFromDateTimeUtcError(FormErrors.timeBefore);
    } else if (
      (validFromDateTimeUtc !== null && isAfter === true) ||
      validFromDateTimeUtc === null
    ) {
      setValidFromDateTimeUtcError("");
    }
  }, [validFromDateTimeUtc]);

  useEffect(() => {
    let isAfter = true;

    if (
      validFromDateTimeUtc &&
      validToDateTimeUtc &&
      validFromDateTimeUtc.isAfter(validToDateTimeUtc)
    ) {
      isAfter = false;
    }

    if (validToDateTimeUtc !== null && isAfter === false) {
      setValidToDateTimeUtcError(FormErrors.timeBefore);
    } else if ((validToDateTimeUtc !== null && isAfter === true) || validToDateTimeUtc === null) {
      setValidToDateTimeUtcError("");
    }
  }, [validToDateTimeUtc]);

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const opt = event.target.value;

    if (opt === FreeBetsText.canBeUsedForFantasy) {
      setCanBeUsedForFantasy(!canBeUsedForFantasy);
    }
    if (opt === FreeBetsText.canBeUsedForSelector) {
      setCanBeUsedForSelector(!canBeUsedForSelector);
    }
    if (opt === FreeBetsText.canJoinPublicPot) {
      setCanJoinPublicPot(!canJoinPublicPot);
    }
    if (opt === FreeBetsText.canJoinPrivatePot) {
      setCanJoinPrivatePot(!canJoinPrivatePot);
    }
    if (opt === FreeBetsText.canCreatePrivatePot) {
      setCanCreatePrivatePot(!canCreatePrivatePot);
    }
  };

  const onSubmit = async () => {
    setButtonLoading(true);

    const tempFreeBet: FreeBet = {
      username,
      amount,
      canBeUsedForFantasy,
      canBeUsedForSelector,
      canJoinPublicPot,
      canJoinPrivatePot,
      canCreatePrivatePot,
      validFromDateTimeUtc,
      validToDateTimeUtc,
    };

    setButtonLoading(false);
    try {
      setAlert("progress");
      setProgress(50);
      setAlertOpen(true);
      const freeBet = await addFreeBets(tempFreeBet);

      if (freeBet && freeBet.success === false) {
        setAlertErrorMessage(`${freeBet.message}`);
        setAlert("error");
      } else {
        setProgress(100);
        setButtonLoading(false);
        setAlert("success");
        setAlertOpen(true);
      }
    } catch (err) {
      setButtonLoading(false);
      setAlertErrorMessage(AlertTextPot.error);
      setAlert("error");
      setAlertOpen(true);
    }
  };

  const submitButton = (): ReactElement => {
    const buttonStyle = {
      width: "100%",
      padding: "8px",
      backgroundColor: submitDisabled ? Colors.Base400 : Colors.Primary600,
      color: Colors.Base050,
    };

    if (buttonLoading === false) {
      return (
        <Button
          disabled={submitDisabled}
          sx={buttonStyle}
          onClick={() => {
            onSubmit();
          }}
        >
          Submit
        </Button>
      );
    } else {
      return (
        <LoadingButton loading variant="outlined" sx={buttonStyle}>
          Submit
        </LoadingButton>
      );
    }
  };

  const handleAlertClose = (_event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const changeAlert = (severity: AlertColor | undefined, text: string, autoHide?: boolean) => {
    return (
      <Snackbar
        open={alertOpen}
        autoHideDuration={autoHide ? null : 6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={severity} sx={{ width: "100%" }}>
          {text}
        </Alert>
      </Snackbar>
    );
  };

  const alertBanner = () => {
    if (alert === "success") {
      return changeAlert("success", AlertTextPot.success);
    } else if (alert === "progress") {
      return (
        <Snackbar open={alertOpen} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="warning" sx={{ width: "100%" }}>
            {AlertTextPot.progress}
            <LinearProgress variant="determinate" value={progress} />
          </Alert>
        </Snackbar>
      );
    } else {
      return changeAlert("error", alertErrorMessage);
    }
  };

  return (
    <form>
      {alertBanner()}
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          padding: "32px",
        }}
      >
        <div className={"H5BoldLeftSecondaryPressed"}>{FreeBetsText.freeBets}</div>
        <TextField
          value={username}
          label={FreeBetsText.username}
          onChange={(event) => {
            handleTextChange(event, null, setUsername, setUsernameError);
          }}
          handleClick={() => {
            handleClick(setUsernameClicked);
          }}
          error={usernameError}
        />
        <TextField
          value={amount}
          label={FreeBetsText.amount}
          onChange={(event) => {
            handleTextChange(event, nonZeroCurrencyValidation, setAmount, setAmountError);
          }}
          handleClick={() => {
            handleClick(setAmountClicked);
          }}
          handleClickAway={() => {
            handleTextClickAway(
              setAmountError,
              amount,
              setAmountClicked,
              amountClicked,
              nonZeroCurrencyValidation,
              FormErrors.text
            );
          }}
          inputAdornment={"£"}
          error={amountError}
        />
        <Checkbox
          checkboxLabels={[
            FreeBetsText.canBeUsedForFantasy,
            FreeBetsText.canBeUsedForSelector,
            FreeBetsText.canJoinPublicPot,
            FreeBetsText.canJoinPrivatePot,
            FreeBetsText.canCreatePrivatePot,
          ]}
          label={FreeBetsText.freeBetOptions}
          onChange={handleCheckbox}
          allDefaultChecked={true}
        />
        <TimeField
          value={validFromDateTimeUtc}
          label={FreeBetsText.validFromDateTimeUtc}
          onChange={(event) => {
            handleTimeChange(event, setValidFromDateTimeUtc);
          }}
          handleClick={() => {
            handleClick(setValidFromDateTimeUtcClicked);
          }}
          dateOnly={false}
          error={validFromDateTimeUtcError}
        />
        <TimeField
          value={validToDateTimeUtc}
          label={FreeBetsText.validToDateTimeUtc}
          onChange={(event) => {
            handleTimeChange(event, setValidToDateTimeUtc);
          }}
          handleClick={() => {
            handleClick(setValidToDateTimeUtcClicked);
          }}
          dateOnly={false}
          error={validToDateTimeUtcError}
        />
        <br />
        {submitButton()}
        <br />
        <br />
        <Button
          variant="outlined"
          sx={{
            width: "100%",
            padding: "8px",
          }}
          onClick={() => {
            clearAll();
          }}
        >
          Clear
        </Button>
      </Grid>
      {/* </Grid> */}
    </form>
  );
};

export default FreeBetScreen;
