import { useState } from "react";

const useRadio = (
  initialValue: string
): [string, (event: React.ChangeEvent<HTMLInputElement>) => void, () => void] => {
  const [radioValue, setRadioValue] = useState<string>(initialValue);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const resetRadio = () => {
    setRadioValue(initialValue);
  };

  return [radioValue, handleRadioChange, resetRadio];
};

export default useRadio;
