import React from "react";

import { InputAdornment, TextField as MUITextField } from "@mui/material";

interface NewTextFieldProps {
  autoComplete?: string;
  autoFocus?: boolean;
  //   color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  //   defaultValue?: string;  //Uncontrolled components
  disabled?: boolean;
  error?: boolean;
  //   FormHelperTextProps?: FormHelperTextProps;
  fullWidth?: boolean;
  helperText?: string;
  id?: string;
  //   InputLabelProps?: InputLabelProps;
  //   inputProps?: InputProps;
  //   InputProps?: InputProps;
  //   inputRef?: React.Ref<HTMLInputElement>;
  label: string;
  margin?: "none" | "dense" | "normal";
  maxRows?: number;
  minRows?: number;
  multiline?: boolean;
  name?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder: string;
  required?: boolean;
  rows?: number;
  size?: "small" | "medium";
  //   sx?: SxProps<Theme>;
  type?: string;
  value: string;
  variant?: "outlined" | "filled" | "standard";
  startAdornment?: string;
}

const NewTextField = ({
  autoComplete = "off",
  autoFocus = false,
  //   color = "primary",
  //   defaultValue = "",
  disabled = false,
  error = false,
  //   FormHelperTextProps = {},
  fullWidth = true,
  helperText = "",
  id = "",
  //   InputLabelProps = {},
  //   inputProps = {},
  //   InputProps = {},
  //   inputRef = undefined,
  label,
  margin = "normal",
  maxRows = 1,
  minRows = 1,
  multiline = false,
  name = "",
  onChange,
  placeholder,
  required = false,
  rows = 1,
  size = "medium",
  //   sx = {},
  type = "text",
  value,
  variant = "outlined",
  startAdornment,
}: NewTextFieldProps) => {
  return (
    <MUITextField
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      label={label}
      margin={margin}
      maxRows={maxRows}
      minRows={minRows}
      multiline={multiline}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      rows={rows}
      size={size}
      type={type}
      value={value}
      variant={variant}
      InputProps={
        startAdornment && {
          startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>,
        }
      }
    />
  );
};

export default NewTextField;
