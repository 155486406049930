import type { Svg } from "../../interfaces/Carousel";
import type { LoginContextInterface } from "../../interfaces/Login";
import type { ApiResponse } from "../../interfaces/PotBuilderForm";
import { carouselURLs } from "../url";

const postFeaturedBannerSvg = async ( 
    data: Array<Svg> 
    ): Promise<ApiResponse | undefined> => {

    const url = carouselURLs.postFeaturedBannerSvg;
    try {
      const token = localStorage.getItem("loginInfo");
      if (token !== null) {
        const storedAccessToken: LoginContextInterface = JSON.parse(token);
        if (storedAccessToken.accessToken != null) {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${storedAccessToken.accessToken}`,
            },
            body: JSON.stringify({ data }),
        });
            try {
                const postFeaturedBannerSvgResponse: ApiResponse = await response.json();
                return postFeaturedBannerSvgResponse;
            } catch (err) {
                console.log(err)
            }
        }
      }
    } catch (err) {
      console.log(err);
    }
}

export default postFeaturedBannerSvg;