import type { ReactElement, SyntheticEvent } from "react";
import React, { useEffect, useState } from "react";

import { Box, CircularProgress, Link } from "@mui/material";
import type { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

import TextField from "../../components/TextField";
import { AlertTextPot, UserDataText, VerifyUserForm } from "../../config/Text";
import { alertBanner, handleTextChange, submitButton } from "../../screens/CreateFantasyPot";
import getUserData from "../api/getUserData";
import getUserPots from "../api/getUserPots";
import getUserReferrals from "../api/getUserReferrals";
import getUserTransactions from "../api/getUserTransactions";
import PaginationTable from "../components/PaginationTable";
import UserInfoDisplay from "../components/UserInfoDisplay";
import type {
  PotsRow,
  ReferralRow,
  TransactionRow,
  UserData,
  UserPots,
  UserReferral,
  UserTransaction,
  Username,
  UsernamePost,
} from "../interfaces/UserData";

const UserDataScreen = (): ReactElement => {
  const [username, setUsername] = useState<string>("");
  const [usernameError, setUsernameError] = useState<string>("");
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>("");
  const [progress, setProgress] = useState<number>();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertErrorMessage, setAlertErrorMessage] = useState<string>("");
  const [userData, setUserData] = useState<UserData>(null);
  const [userTransactions, setUserTransactions] = useState<UserTransaction[]>(null);
  const [userReferrals, setUserReferrals] = useState<UserReferral[]>(null);
  const [userPots, setUserPots] = useState<UserPots[]>(null);
  const [transactionSkip, setTransactionSkip] = useState<number>(0);
  const [referralSkip, setReferralSkip] = useState<number>(0);
  const [potsSkip, setPotsSkip] = useState<number>(0);
  const [userDataLoading, setUserDataLoading] = useState<boolean>(false);
  const [userTransactionsLoading, setUserTransactionsLoading] = useState<boolean>(false);
  const [userReferralsLoading, setUserReferralsLoading] = useState<boolean>(false);
  const [userPotsLoading, setUserPotsLoading] = useState<boolean>(false);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GBP",
  });

  useEffect(() => {
    getTransactions();
  }, [transactionSkip]);

  useEffect(() => {
    getPots();
  }, [potsSkip]);

  useEffect(() => {
    getReferrals();
  }, [referralSkip]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("Username")) {
      const searchedUsername = searchParams.get("Username");
      setUsername(searchedUsername);
      onSubmit(searchedUsername);
    }
  }, []);

  const updateData = async (userId: string) => {
    if (userId) {
      await getTransactions(userId);
      setUserTransactionsLoading(false);
      setProgress(50);
      await getReferrals(userId);
      setUserReferralsLoading(false);
      setProgress(75);
      await getPots(userId);
      setUserPotsLoading(false);
      setProgress(100);
      setButtonLoading(false);
      setAlert("success");
      setAlertOpen(true);
    }
  };

  const getTransactions = async (userId?: string) => {
    let tempUserId = userId;
    if (!tempUserId && !!userData && !!userData.userId) {
      tempUserId = userData.userId;
    }

    if (!tempUserId) {
      return;
    }

    const transactionsPost: UsernamePost = {
      userId: tempUserId,
      take: "50",
      skip: String(transactionSkip),
    };

    try {
      const userTransactionsResults = await getUserTransactions(transactionsPost);
      setUserTransactions(userTransactionsResults.data);
    } catch (err) {
      console.log(err);
    }
  };

  const updateURL = (value: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("Username", value);
    const newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
    history.pushState(null, "", newRelativePathQuery);
  };

  const getReferrals = async (userId?: string) => {
    let tempUserId = userId;
    if (!tempUserId && !!userData && !!userData.userId) {
      tempUserId = userData.userId;
    }

    if (!tempUserId) {
      return;
    }

    const referralsPost: UsernamePost = {
      userId: tempUserId,
      take: "50",
      skip: String(referralSkip),
    };

    try {
      const userReferralsResults = await getUserReferrals(referralsPost);
      setUserReferrals(userReferralsResults.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getPots = async (userId?: string) => {
    let tempUserId = userId;
    if (!tempUserId && !!userData && !!userData.userId) {
      tempUserId = userData.userId;
    }

    if (!tempUserId) {
      return;
    }

    const potsPost: UsernamePost = {
      userId: tempUserId,
      take: "50",
      skip: String(potsSkip),
    };

    try {
      const userPotsResults = await getUserPots(potsPost);
      setUserPots(userPotsResults.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (tempUsername?: string) => {
    setUserData(null);
    setButtonLoading(true);

    const tempData: Username = {
      username: tempUsername ? tempUsername : username,
    };

    try {
      setAlert("progress");
      setProgress(0);
      setAlertOpen(true);
      setButtonLoading(true);
      setUserDataLoading(true);
      setUserTransactionsLoading(true);
      setUserReferralsLoading(true);
      setUserPotsLoading(true);
      const userDataResults = await getUserData(tempData);
      const dataTemp: UserData = userDataResults.data;
      setProgress(25);
      if (userDataResults.success === false) {
        setAlertErrorMessage(`${userDataResults.message}`);
        setAlert("error");
        setUserDataLoading(false);
        setButtonLoading(false);
      } else {
        updateURL(tempData.username);
        setUserData(dataTemp);
        setUserDataLoading(false);
        updateData(dataTemp.userId);
      }
    } catch (err) {
      console.log(err);
      setButtonLoading(false);
      setAlertErrorMessage(AlertTextPot.error);
      setAlert("error");
      setAlertOpen(true);
      setUserDataLoading(false);
    }
  };

  const handleAlertClose = (_event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const getTransactionValues = () => {
    const tempTransactions: TransactionRow[] = [];

    if (!userTransactions) {
      return tempTransactions;
    }

    userTransactions.forEach((transaction: UserTransaction) => {
      const tempTransaction: TransactionRow = {
        id: Number(transaction.UserTransactionId),
        UserTransactionId: Number(transaction.UserTransactionId),
        TransactionAction: transaction.TransactionAction,
        TransactionType: transaction.TransactionType,
        TransactionValue: String(transaction.TransactionValue),
        CreatedOnDateTimeUtc: transaction.CreatedOnDateTimeUtc,
      };

      tempTransactions.push(tempTransaction);
    });

    return tempTransactions;
  };

  const getReferralValues = () => {
    const tempReferrals: ReferralRow[] = [];

    if (!userReferrals) {
      return tempReferrals;
    }

    userReferrals.forEach((referral: UserReferral) => {
      let referredFirstDepositAtDateTimeUtc: string | Dayjs | null = "Not Deposited";
      let referralCompletedAtDateTimeUtc: string | Dayjs | null = "Not Complete";
      let referredHasVerifiedIdentity = "False";
      let totalPaidEntriesAmount = "";

      if (referral.totalPaidEntriesAmount > 0) {
        totalPaidEntriesAmount = "True";
      } else {
        totalPaidEntriesAmount = "False";
      }

      if (referral.referredHasVerifiedIdentity === true) {
        referredHasVerifiedIdentity = "True";
      }

      if (referral.referralCompletedAtDateTimeUtc !== null) {
        referredFirstDepositAtDateTimeUtc = referral.referredFirstDepositAtDateTimeUtc;
      }

      if (referral.referralCompletedAtDateTimeUtc !== null) {
        referralCompletedAtDateTimeUtc = referral.referralCompletedAtDateTimeUtc;
      }

      const tempReferral: ReferralRow = {
        id: dayjs(referral.createdAtDateTimeUtc).format("DD/MM/YYYY HH:mm:ss"),
        ReferredUserId: Number(referral.referredUserId),
        ReferredUsername: referral.referredUsername,
        ReferredHasVerifiedIdentity: referredHasVerifiedIdentity,
        ReferredFirstDepositAtDateTimeUtc: referredFirstDepositAtDateTimeUtc,
        TotalPaidEntriesAmount: String(referral.totalPaidEntriesAmount),
        CreatedAtDateTimeUtc: referral.createdAtDateTimeUtc,
        ReferralCompletedAtDateTimeUtc: referralCompletedAtDateTimeUtc,
      };

      tempReferrals.push(tempReferral);
    });

    return tempReferrals;
  };

  const getPotValues = () => {
    const tempPots: PotsRow[] = [];

    if (!userPots) {
      return tempPots;
    }

    userPots.forEach((pot: UserPots) => {
      let { payoutAmount } = pot;
      const { entryFee } = pot;
      let potType = "Fantasy";
      let hasBeenSettled = "False";

      if (pot.potType !== "public") {
        potType = pot.potType;
      }

      if (pot.payoutAmount === null) {
        payoutAmount = 0.0;
      }

      if (pot.hasBeenSettled === true) {
        hasBeenSettled = "True";
      }

      const tempPot: PotsRow = {
        id: pot.userPotEntryId,
        potId: Number(pot.potId),
        potType: potType,
        potName: pot.potName,
        potCode: pot.potCode,
        startDateTimeUtc: pot.startDateTimeUtc,
        entryFee: String(entryFee),
        payoutAmount: String(payoutAmount),
        hasBeenSettled: hasBeenSettled,
        finalPosition: pot.finalPosition,
      };

      tempPots.push(tempPot);
    });

    return tempPots;
  };

  const transactionColumns: GridColDef[] = [
    {
      field: "UserTransactionId",
      headerName: "User Transaction ID",
      width: 200,
    },
    {
      field: "TransactionAction",
      headerName: "Action",
      width: 200,
    },
    {
      field: "TransactionType",
      headerName: "Type",
      width: 250,
    },
    {
      field: "TransactionValue",
      headerName: "Value",
      sortComparator: (a: string, b: string) => Number(a.slice(1)) - Number(b.slice(1)),
      valueFormatter: (params) => formatter.format(Number(params?.value)),
      width: 150,
    },
    {
      field: "CreatedOnDateTimeUtc",
      headerName: "Created on Date/Time",
      width: 250,
      valueFormatter: (params) => dateStringFormatter(params),
    },
  ];

  const dateStringFormatter = (params: GridValueFormatterParams<any>) => {
    if (dayjs(params.value).format("DD/MM/YYYY HH:mm:ss") !== "Invalid Date") {
      return dayjs(params.value).format("DD/MM/YYYY HH:mm:ss");
    } else {
      return params?.value;
    }
  };

  const referralColumns: GridColDef[] = [
    {
      field: "ReferredUserId",
      headerName: "Referred User ID",
      width: 200,
    },
    {
      field: "ReferredUsername",
      headerName: "Referred Username",
      width: 200,
      renderCell: (params) => (
        <Link href={`${window.location.origin}/UserInfo?Username=${params.value}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "ReferredHasVerifiedIdentity",
      headerName: "Has Verified Identity",
      width: 250,
    },
    {
      field: "ReferredFirstDepositAtDateTimeUtc",
      headerName: "First Deposit Date/Time",
      width: 250,
      valueFormatter: (params) => dateStringFormatter(params),
    },
    {
      field: "TotalPaidEntriesAmount",
      headerName: "Paid Pot Entry Amount",
      sortComparator: (a: string, b: string) => Number(a.slice(1)) - Number(b.slice(1)),
      valueFormatter: (params) => formatter.format(Number(params?.value)),
      width: 250,
    },
    {
      field: "CreatedAtDateTimeUtc",
      headerName: "Created At Date/Time",
      width: 250,
      valueFormatter: (params) => dateStringFormatter(params),
    },
    {
      field: "ReferralCompletedAtDateTimeUtc",
      headerName: "Completed At Date/Time",
      width: 250,
      valueFormatter: (params) => dateStringFormatter(params),
    },
  ];

  const potColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
    },
    {
      field: "potType",
      headerName: "Type",
      width: 200,
    },
    {
      field: "potName",
      headerName: "Name",
      width: 350,
    },
    {
      field: "potCode",
      headerName: "Pot Code",
      width: 250,
    },
    {
      field: "entryFee",
      headerName: "Entry Fee",
      sortComparator: (a: string, b: string) => Number(a.slice(1)) - Number(b.slice(1)),
      valueFormatter: (params) => formatter.format(Number(params?.value)),
      width: 150,
    },
    {
      field: "startDateTimeUtc",
      headerName: "Started At Date/Time",
      width: 250,
      valueFormatter: (params) => dayjs(params?.value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: "payoutAmount",
      headerName: "Payout Amount",
      sortComparator: (a: string, b: string) => Number(a.slice(1)) - Number(b.slice(1)),
      valueFormatter: (params) => formatter.format(Number(params?.value)),
      width: 150,
    },
    {
      field: "hasBeenSettled",
      headerName: "Settled",
      width: 250,
    },
    {
      field: "finalPosition",
      headerName: "Final Position",
      type: "number",
      width: 150,
    },
  ];

  const Loading = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          paddding: "20px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  const userDataTable =
    userDataLoading === true ? <Loading /> : <UserInfoDisplay userData={userData} />;

  const userTransactionsTable =
    userTransactionsLoading === true ? (
      <Loading />
    ) : (
      <PaginationTable
        records={userTransactions}
        skip={transactionSkip}
        setSkip={setTransactionSkip}
        getValues={getTransactionValues}
        columns={transactionColumns}
      />
    );

  const userReferralsTable =
    userReferralsLoading === true ? (
      <Loading />
    ) : (
      <PaginationTable
        records={userReferrals}
        skip={referralSkip}
        setSkip={setReferralSkip}
        getValues={getReferralValues}
        columns={referralColumns}
      />
    );

  const userPotsTable =
    userPotsLoading === true ? (
      <Loading />
    ) : (
      <PaginationTable
        records={userPots}
        skip={potsSkip}
        setSkip={setPotsSkip}
        getValues={getPotValues}
        columns={potColumns}
      />
    );

  const UserDisplayToggle = userData !== null && userTransactions !== null && (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignContent: "center",
        padding: "20px",
      }}
    >
      <div className={"H5BoldLeftSecondaryPressed"}>{UserDataText.basicInfo}</div>
      {userDataTable}
      <div className={"H5BoldLeftSecondaryPressed"}>{UserDataText.transactions}</div>
      {userTransactionsTable}
      <div className={"H5BoldLeftSecondaryPressed"}>{UserDataText.referrals}</div>
      {userReferralsTable}
      <div className={"H5BoldLeftSecondaryPressed"}>{UserDataText.pots}</div>
      {userPotsTable}
    </div>
  );

  return (
    <Box sx={{ padding: "30px" }}>
      {alertBanner(alert, alertOpen, handleAlertClose, progress, alertErrorMessage)}
      <div className={"H5BoldLeftSecondaryPressed"}>{UserDataText.userDetails}</div>
      <TextField
        value={username}
        label={VerifyUserForm.username}
        required={true}
        onChange={(event) => {
          handleTextChange(event, null, setUsername, setUsernameError);
        }}
        error={usernameError}
      />
      <br />
      {submitButton(submitDisabled, buttonLoading, onSubmit)}
      <br />
      {UserDisplayToggle}
      <br />
    </Box>
  );
};

export default UserDataScreen;
