import React from "react";

import { Button as MuiButton } from "@mui/material";

interface ButtonProps {
  children: React.ReactNode;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  onClick: () => void;
  enabled?: boolean;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  size?: "small" | "medium" | "large";
  variant?: "text" | "outlined" | "contained";
  fullWidth?: boolean;
}

const Button = ({
  children,
  onClick,
  enabled = true,
  color = "primary",
  endIcon,
  startIcon,
  size = "large",
  variant = "contained",
  fullWidth = true,
}: ButtonProps) => {
  return (
    <MuiButton
      onClick={onClick}
      disabled={!enabled}
      color={color}
      endIcon={endIcon}
      startIcon={startIcon}
      size={size}
      variant={variant}
      fullWidth={fullWidth}
      sx={{
        margin: "8px 0 8px 0",
      }}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
