import type {
  LoginContextInterface
} from "../../interfaces/Login";
import { carouselURLs } from "../url";

const getFeaturedBannerData = async () => {
  const url = carouselURLs.getFeaturedBannerData;
  try {
    const token = localStorage.getItem("loginInfo");
    if (token !== null) {
      const storedAccessToken: LoginContextInterface = JSON.parse(token);
      if (storedAccessToken.accessToken != null) {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${storedAccessToken.accessToken}`,
          },
        });

        try {
          const featuredBannerResponse = await response.json();
          return featuredBannerResponse;
        } catch (err) {
          console.log(err);
        }
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export default getFeaturedBannerData;
