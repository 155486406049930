import React from "react";

import type { SelectChangeEvent } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export interface DropDownOption {
  value: string;
  label: string;
}

interface NewDropDownFieldProps {
  autoWidth?: boolean;
  defaultOpen?: boolean;
  //defaultValue?: string; //Uncontrolled components
  disabled?: boolean;
  //   displayEmpty?: boolean;
  IconComponent?: React.ElementType;
  id?: string;
  label?: string;
  labelId?: string;
  multiple?: boolean;
  native?: boolean;
  options: DropDownOption[];
  onChange: (event: SelectChangeEvent<string>) => void;
  open?: boolean;
  value: string;
  variant?: "outlined" | "filled" | "standard";
}

const NewDropDownField = ({
  autoWidth = false,
  defaultOpen = false,
  disabled = false,
  //   displayEmpty = false,
  id,
  label,
  labelId,
  multiple = false,
  native = false,
  options,
  onChange,
  open,
  value,
  variant = "outlined",
}: NewDropDownFieldProps) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        fullWidth
        autoWidth={autoWidth}
        defaultOpen={defaultOpen}
        disabled={disabled}
        id={id}
        label={label}
        labelId={labelId}
        multiple={multiple}
        native={native}
        open={open}
        onChange={onChange}
        value={value}
        variant={variant}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NewDropDownField;
