import { useState } from "react";

import type { BetBuilderQuestion } from "./SelectorOptions";

interface SelectedQuestion extends BetBuilderQuestion {
  order: number;
}

type UseBetBuilderQuestions = [
  SelectedQuestion[], // selectedQuestions
  (question: BetBuilderQuestion, isSelected: boolean, manualOrder?: number) => void, // handleQuestionChange
  () => void // resetQuestions
];

const useBetBuilderQuestions = (): UseBetBuilderQuestions => {
  const [selectedQuestions, setSelectedQuestions] = useState<SelectedQuestion[]>([]);

  const handleQuestionChange = (
    question: BetBuilderQuestion,
    isSelected: boolean,
    manualOrder?: number
  ) => {
    if (isSelected) {
      // If a manual order is provided, use it, otherwise use the next available order
      const order = manualOrder !== undefined ? manualOrder : selectedQuestions.length + 1;

      setSelectedQuestions((prev) => [...prev, { ...question, order }]);
    } else {
      // Remove the question if deselected
      setSelectedQuestions((prev) => prev.filter((q) => q.questionId !== question.questionId));
    }
  };

  const resetQuestions = () => {
    setSelectedQuestions([]);
  };

  return [selectedQuestions, handleQuestionChange, resetQuestions];
};

export default useBetBuilderQuestions;
