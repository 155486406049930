import type { LoginContextInterface } from "../../interfaces/Login";
import type { ApiResponse } from "../../interfaces/PotBuilderForm";
import type { VerifiedUser } from "../../interfaces/VerifiedUser";
import { verifiedUserDataUrls } from "../url";

const postVerifiedUserData = async ( 
    data: VerifiedUser
    ): Promise<ApiResponse | undefined> => {

    const url = verifiedUserDataUrls.postVerifiedUserData;
    try {
      const token = localStorage.getItem("loginInfo");
      if (token !== null) {
        const storedAccessToken: LoginContextInterface = JSON.parse(token);
        if (storedAccessToken.accessToken != null) {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${storedAccessToken.accessToken}`,
            },
            body: JSON.stringify({ data }),
        });
            try {
                const postVerifiedUserDataResponse: ApiResponse = await response.json();
                return postVerifiedUserDataResponse;
            } catch (err) {
                console.log(err)
            }
        }
      }
    } catch (err) {
      console.log(err);
    }
}

export default postVerifiedUserData;