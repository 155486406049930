import type { ReactElement } from "react";
import React from "react";

import { PotBuilderFormHeaders } from "../../config/Text";
import type { Competition } from "../../interfaces/Fixture";
import AccordionDrop from "../Accordion";

interface FixturesTableProps {
  fixtureData: Competition[];
  checkedTeamId: number[];
  fixtureIds: number[];
  fixtureError: string;
  handleClick: () => void;
  handleClickAway: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FixturesTable = ({
  fixtureData,
  checkedTeamId,
  fixtureIds,
  fixtureError,
  handleClick,
  handleClickAway,
  handleChange,
}: FixturesTableProps): ReactElement => {
  return (
    <>
      <div className={"H5BoldLeftSecondaryPressed"}>{PotBuilderFormHeaders.fixtures}</div>
      <AccordionDrop
        data={fixtureData}
        handleClick={handleClick}
        handleClickAway={handleClickAway}
        onChange={handleChange}
        error={fixtureError}
        selectedItemIds={fixtureIds}
        checkedTeamIds={checkedTeamId}
      />
    </>
  );
};

export default FixturesTable;
