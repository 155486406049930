import type { FreeBet } from "../../interfaces/FreeBet";
import type { LoginContextInterface } from "../../interfaces/Login";
import type { ApiResponse } from "../../interfaces/PotBuilderForm";
import { freeBetUrls } from "../url";

const addFreeBets = async (
  data: FreeBet
  ): Promise<ApiResponse > => {
    const url = freeBetUrls.addFreeBets;
    try {
      const token = localStorage.getItem("loginInfo");
      if (token !== null) {
        const storedAccessToken: LoginContextInterface = JSON.parse(token);
        if (storedAccessToken.accessToken != null) {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${storedAccessToken.accessToken}`,
            },
            body: JSON.stringify({ data }),
          });
            const addFreeBetsResponse: ApiResponse = await response.json();
            return addFreeBetsResponse;
          }
        }
      
        return {
            success: false,
            data: null,
            message: "Could not get token",
        };
    } catch (e) {
        return {
            success: false,
            data: null,
            message: JSON.stringify(e),
        };;
    }
    
};
  
export default addFreeBets;
  