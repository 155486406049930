import { useCallback, useState } from "react";

const useNewDropDownField = (
  initialValue = ""
): [string, (newValue: string) => void, () => void] => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = useCallback((newValue: string) => {
    setValue(newValue);
  }, []);

  const reset = useCallback(() => {
    setValue(initialValue);
  }, [initialValue]);

  return [value, handleChange, reset];
};

export default useNewDropDownField;
