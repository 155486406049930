import type { ReactElement } from "react";
import React from "react";


import { DraggableTable } from "../components/DraggableTable";

const CarouselScreen = (): ReactElement => {
  return (
    <>
      <DraggableTable />
    </>
  );
};

export default CarouselScreen;
