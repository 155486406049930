
import type { LoginContextInterface } from "../../interfaces/Login";
import type {
  ApiResponse,
} from "../../interfaces/PotBuilderForm";
import type { Selector } from "../../interfaces/Selector";
import { selectorURLs } from "../url";

const postFormData = async (
  data: Selector
): Promise<ApiResponse | undefined> => {
  const url = selectorURLs.postSelectorFormData;
  try {
    const token = localStorage.getItem("loginInfo");
    if (token !== null) {
      const storedAccessToken: LoginContextInterface = JSON.parse(token);
      if (storedAccessToken.accessToken != null) {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${storedAccessToken.accessToken}`,
          },
          body: JSON.stringify({ data }),
        });
          const selectorResponse: ApiResponse = await response.json();
          return selectorResponse;
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export default postFormData;
