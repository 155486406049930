import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

import { FormErrors } from "../config/Text";

// Define the validation function for potName
export const validatePotName = (value: string): string => {
  if (value.length === 0 || value.length > 70) {
    return FormErrors.text;
  }
  return "";
};

// Validation function for payout
export const validatePayout = (value: string): string => {
  // Check if value is empty
  if (value.length === 0) {
    return "Payout is required";
  }

  // Check for non-numerical characters except decimal point
  if (!/^[0-9]+(\.[0-9]+)?$/.test(value)) {
    return "Payout must contain only numbers";
  }

  // Check if decimal point is present, there must be exactly 2 digits after it
  if (value.includes(".")) {
    const decimalPart = value.split(".")[1];
    if (decimalPart.length !== 2) {
      return "Payout must have exactly 2 decimal places (e.g., 100.00)";
    }
  }

  // Check if the value is a positive number
  const numValue = parseFloat(value);
  if (numValue < 0) {
    return "Payout must be zero or a positive number";
  }

  // Check if the value is within a reasonable range (optional)
  if (numValue > 10000) {
    return "Payout exceeds maximum allowed value";
  }

  // Check if the value has no more than 2 decimal places
  if (!/^\d+(\.\d{1,2})?$/.test(value)) {
    return "Payout cannot have more than 2 decimal places";
  }

  return "";
};

export const validateEntryFee = (value: string): string => {
  if (value.length === 0) {
    return "Entry fee is required";
  }

  // Check for non-numerical characters except decimal point
  if (!/^[0-9]+(\.[0-9]+)?$/.test(value)) {
    return "Entry fee must contain only numbers and at most one decimal point";
  }

  // Check if decimal point is present, there must be exactly 2 digits after it
  if (value.includes(".")) {
    const decimalPart = value.split(".")[1];
    if (decimalPart.length !== 2) {
      return "Entry fee must have exactly 2 decimal places (e.g., 10.00)";
    }
  }

  return "";
};

// Validation function for jackpotPayoutAmount
export const validateJackpotPayoutAmount = (value: string): string => {
  if (value.length === 0) {
    return "";
  }

  // If value is provided, validate it as currency
  if (value.length > 0) {
    // Check for non-numerical characters except decimal point
    if (!/^[0-9]+(\.[0-9]+)?$/.test(value)) {
      return "Jackpot payout amount must contain only numbers";
    }

    // Check if decimal point is present, there must be exactly 2 digits after it
    if (value.includes(".")) {
      const decimalPart = value.split(".")[1];
      if (decimalPart.length !== 2) {
        return "Jackpot payout amount must have exactly 2 decimal places (e.g., 1000.00)";
      }
    }

    // Check if the value is a positive number
    const numValue = parseFloat(value);
    if (numValue <= 0) {
      return "Jackpot payout amount must be greater than zero";
    }
  }

  return "";
};

// Validation function for jackpotPointsThreshold
export const validateJackpotPointsThreshold = (
  value: string,
  jackpotPayoutAmount: string
): string => {
  // Both fields can be empty together (optional feature)
  if (value.length === 0 && jackpotPayoutAmount.length === 0) {
    return "";
  }

  // If one is filled but the other is empty, show error
  if (value.length === 0 && jackpotPayoutAmount.length > 0) {
    return "Jackpot payout amount is required when points threshold is provided";
  }

  // If value is provided, validate it as a number
  if (value.length > 0) {
    // Check for non-numerical characters
    if (!/^[0-9]+$/.test(value)) {
      return "Jackpot points threshold must be a whole number";
    }

    // Check if the value is a positive number
    const numValue = parseInt(value, 10);
    if (numValue <= 0) {
      return "Jackpot points threshold must be greater than zero";
    }
  }

  return "";
};

// Validation function for maxEntrants
export const validateMaxEntrants = (value: string): string => {
  // Check for non-numerical characters (must be a whole number)
  if (value.length > 0 && !/^[0-9]+$/.test(value)) {
    return "Maximum entrants must be a whole number";
  }

  return "";
};

// Validation function for maxEntrantsPerUser
export const validateMaxEntrantsPerUser = (value: string): string => {
  // Check if value is empty
  if (value.length === 0) {
    return "Maximum entrants per user is required";
  }

  // Check for non-numerical characters (must be a whole number)
  if (!/^[0-9]+$/.test(value)) {
    return "Maximum entrants per user must be a whole number";
  }

  return "";
};

// Validate pot start time
export const validatePotStartTime = (value: Dayjs | null): string => {
  // Check if value is provided
  if (value === null) {
    return "Pot start time is required";
  }

  // Check if time is in the future
  const currentTime = dayjs();
  if (!value.isAfter(currentTime)) {
    return "Pot start time must be in the future";
  }

  return "";
};

// Validate display time
export const validateDisplayTime = (value: Dayjs | null): string => {
  // Display time is optional
  if (value === null) {
    return "";
  }

  // If provided, check if time is in the future
  const currentTime = dayjs();
  if (!value.isAfter(currentTime)) {
    return "Display time must be in the future";
  }

  return "";
};

// Validate that display time is after pot start time
export const validateDisplayTimeAfterPotStartTime = (
  value: Dayjs | null,
  potStartTime: Dayjs | null
): string => {
  // If either value is not provided, no validation needed
  if (value === null || potStartTime === null) {
    return "";
  }

  // Check if display time is after pot start time
  if (!value.isAfter(potStartTime)) {
    return "Display time must be after pot start time";
  }

  return "";
};
