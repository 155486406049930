export const PotCreatorForm = {
  leftIcon: "Left Icon",
  rightIcon: "Right Icon",
  potName: "Pot Name",
  entryFee: "Entry Fee",
  payout: "Payout",
  numberOfWinners: "Number of Winners",
  payoutStructure: "Payout Structure",
  winningsPaid: "Winnings Paid",
  maxEntrants: "Max Entrants",
  maxEntrantsPerUser: "Max Entrants Per User",
  isPinned: "Is Pinned",
  type: "Type",
  potForNewUsers: "Pot For New Users",
  selectFixtures: "Select Fixtures",
  potCode: "Pot Code",
  maxPlayersPerClub: "Max Players Per Club",
  potStartTime: "Pot Start Time",
  displayTime: "Display Time",
  capped: "Capped Pots",
  potType: "Pot Type",
  jackpotPayoutAmount: "Jackpot Payout Amount",
  jackpotPointsThreshold: "Jackpot Points Threshold",
};

export const PotBuilderCappedOptions = {
  capped120: "20-£1 Capped Pot",
  capped320: "20-£3 Capped Pot",
  capped510: "10-£5 Capped Pot",
  capped1010: "10-£10 Capped Pot",
  capped1510: "15-£10 Capped Pot",
};

export const PotBuilderFormOptions = {
  true: "True",
  false: "False",
  money: "Money",
  freeBets: "Free Bets",
  public: "Public",
  private: "Private",
  fullTimeResult: "Full Time Result",
  goalScorer: "Goal Scorer",
  bothTeamsToScore: "Both Teams To Score",
};

export const PotBuilderFormHeaders = {
  naming: "Naming:",
  money: "Money:",
  potInformation: "Pot Information:",
  fixtures: "Fixtures:",
  gameInformation: "Game Information:",
  gameType: "Game Type",
};

export const VerifyFormHeaders = {
  userDetails: "User Details:",
  address: "Address:",
  proofOfIdentity: "Proof of Identity:",
};

export const FreeBetFormHeaders = {
  addFunds: "Add Funds:",
};

export const NavBarButtons = {
  potBuilder: "Fantasy Pot Builder",
  addFreeBets: "Add Free Bets",
  verifyUser: "Verify User",
  carousel: "Carousel",
  metrics: "Metrics",
  selector: "Selector Pot Builder",
  userInfo: "User Info",
  builder: "Create Builder Pot",
};

export const FormErrors = {
  icon: "Select a Value",
  fixture: "Select a Fixture",
  text: "Invalid Input",
  time: "Select a Pot Start Time",
  timeBefore: "Selected Time is Invalid",
  age: "User is under 18",
  payoutStructure: "Invalid input. Must be a sum of 100",
  descendingOrder: "Invalid input. Numbers must be in descending order",
  maxPlayers: "Invalid input. Please choose a value between 2 and 6",
  entryFee: "Invalid input. Value must be between 1 and 100",
  payout: "Invalid input. Value must be less than £5000",
  entryFeeHigher: "Entry fee cannot be greater than payout",
  fileName: "Input can only contain letters, numbers and '-'",
  starting: "Invalid length for Pot Code",
  jackpotPayoutAmount: "Jackpot information fields must be empty or both completed",
  maxEntrantsPerUser: "Maximum entrants must be between 1 and 10",
};

export const LoginText = {
  phoneNumberLabel: "Phone Number",
  leftInputAdornment: "+44",
  error: "Invalid Input",
  verificationCodeLabel: "Verification Code",
  phoneNumberError: "Invalid Phone Number",
  verificationCodeError: "Invalid Verification Code",
};

export const VerifyUserForm = {
  username: "Username",
  forename: "Forename",
  lastname: "Lastname",
  email: "Email",
  dateOfBirth: "Date of Birth",
  postcode: "Postcode",
  thoroughfare: "Thoroughfare",
  addressLine1: "Address Line 1",
  buildingNumber: "Building Number",
  townOrCity: "Town/City",
  county: "County",
  district: "District",
  country: "Country",
  proofOfIdentityType: "Proof of Identity",
  proofOfAddressType: "Proof of Address",
  verifiedBy: "Verified By",
};

export const FreeBetForm = {
  userId: "User ID",
  amount: "Amount",
};

export const AlertTextPot = {
  success: "Success!",
  progress: "In progress...",
  error: "Error.",
};

export const AlertTextBanner = {
  success: "Success! - Featured Banners Successfully Updated.",
  progress: "Updating Carousel...",
  error: "Error - Carousel Update Failed.",
};

export const bannersText = {
  dev: "DEV",
  prod: "PROD",
};

export const CarouselFormText = {
  name: "File Name",
  potCode: "Pot Code",
  type: "Type",
  showFromDateTimeUtc: "Show From Date/Time",
  showUntilDateTimeUtc: "Show Until Date/Time",
  confrimDeletion: "Are you sure you want to delete banner ",
  confirmSave: "Are you sure you want to save your changes",
};

export const CarouselTypeOptions = {
  pot: "Pot",
  rabm: "RABM",
};

export const FreeBetsText = {
  freeBets: "Free Bets",
  username: "Username",
  amount: "Amount",
  canBeUsedForFantasy: "Can Be Used For Fantasy",
  canBeUsedForSelector: "Can Be Used For Selector",
  canJoinPublicPot: "Can Join Public Pot",
  canJoinPrivatePot: "Can Join Private Pot",
  canCreatePrivatePot: "Can Create Private Pot",
  validFromDateTimeUtc: "Valid From Date Time",
  validToDateTimeUtc: "Valid To Date Time",
  freeBetOptions: "Free Bet Options",
};

export const UserDataText = {
  userDetails: "User Details",
  basicInfo: "Basic Info",
  transactions: "Transactions",
  referrals: "Referrals",
  pots: "Pots",
};

export const FreeBetErrors = {
  amount: "Amount must be more than £1 and less than £100",
  validFromDateTimeUtc:
    "'Valid From Date/Time' must be be empty or must be before 'Valid To Date/Time'",
  validToDateTimeUtc:
    "'Valid To Date/Time' must be be empty or must be after 'Valid From Date/Time'",
};

export const VerifyUserHeaders = {
  userDetails: "User Details",
  address: "Address",
  proofOfIdentity: "Proof of Identity",
};

export const verifyUserErrors = {
  forename: "Forename must contain letters, apostrophes, hyphens and spaces",
  lastname: "Forename must contain letters, apostrophes, hyphens and spaces",
};
